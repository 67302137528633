import { ethers } from "ethers";
import { getContract } from "../Addresses";

const TOKENS = {
  8453: [
    // base
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      isNative: true,
      isShortable: true,
      displayDecimals:4
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x4200000000000000000000000000000000000006",
      coingeckoUrl: "https://www.coingecko.com/en/coins/wrapped-ethereum",
      isWrapped: true,
      baseSymbol: "ETH",
      displayDecimals:4
    },
    {
      name: "Axelar Wrapped USDC",
      symbol: "axlUSDC",
      address: "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
      coingeckoUrl: "https://www.coingecko.com/en/coins/axlusdc",
      decimals: 6,
      isStable: true,
      displayDecimals:4
    },
    {
      name: "USD Base Coin",
      symbol: "USDbC",
      address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      coingeckoUrl: "https://www.coingecko.com/en/coins/bridged-usd-coin-layerzero",
      decimals: 6,
      isStable: true,
      displayDecimals:4
    },
    /*
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0x74b23882a30290451A17c44f4F05243b6b58C76d",
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      decimals: 18,
      isShortable: true,
      displayDecimals:2
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      address: "0x321162Cd933E2Be498Cd2267a90534A804051b11",
      coingeckoUrl: "https://www.coingecko.com/en/coins/bitcoin",
      decimals: 8,
      isShortable: true,
      displayDecimals:2
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
      decimals: 6,
      isStable: true,
      displayDecimals:4
    },
    {
      name: "USDT",
      symbol: "USDT",
      address: "0x049d68029688eabf473097a2fc38ef61633a3c7a",
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
      decimals: 6,
      isStable: true,
      displayDecimals:4
    },*/
    {
      name: "Dai",
      symbol: "DAI",
      address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
      coingeckoUrl: "https://www.coingecko.com/en/coins/dai",
      decimals: 18,
      isStable: true,
      displayDecimals:4
    },
    
  ],
};

const ADDITIONAL_TOKENS = {
  8453: [
    {
      name: "BASE",
      symbol: "BASE",
      address: getContract(8453, "BASE"),
      decimals: 18,
    },
    {
      name: "Escrowed BASE",
      symbol: "esBASE",
      address: getContract(8453, "ES_BASE"),
      decimals: 18,
    },
    {
      name: "BASE LP",
      symbol: "BLP",
      address: getContract(8453, "BLP"),
      decimals: 18,
    },
  ],
};

const CHAIN_IDS = [8453];

const TOKENS_MAP = {};
const TOKENS_BY_SYMBOL_MAP = {};

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

const WRAPPED_TOKENS_MAP = {};
const NATIVE_TOKENS_MAP = {};
for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId) {
  return TOKENS[chainId];
}

export function isValidToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    localStorage.removeItem("Exchange-token-selection-v2");
    localStorage.removeItem("BuyBlp-swap-token-address");
    window.location.reload();
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId, symbol) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDB");
}
