import React from "react";

import "./Footer.css";

import logoImg from "./img/elp-logo-black-min.png";
import twitterIcon from "./img/ic_twitter.svg";
import discordIcon from "./img/ic_discord.svg";
import telegramIcon from "./img/ic_telegram.svg";
import githubIcon from "./img/ic_github.svg";
import mediumIcon from "./img/ic_medium.svg";
import gitbookIcon from "./img/ic_gitbook.svg";
import ChainlinkIcon from './img/chainlink.svg'
import BaseIcon from './img/logo-base.png'
import linktreeIcon from "./img/linktree_white.svg";
import logoBlpExchangeImg from "./img/swapbased-logo.png";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div className="Footer">
      <div className="Footer-wrapper">
        <div className="Footer-logo">
        <span className="mx-2" style={{fontSize:25}}><b><span className="titletextcolor"><img
                      src={logoBlpExchangeImg}
                      style={{ width: "100%", height: "auto", maxWidth:"150px" }}
                      className="big"
                      alt="BASE Logo"
                    /></span></b></span>
        </div>
        <div>
          <p><a href="https://swapbased.finance/" target="_blank" className="ahreftextcolorfooter">Powered by SwapBased.Finance</a></p>
        </div>
        <div className="Footer-social-link-block smallscreensize">
          <a className="App-social-link" href="https://twitter.com/swap_based" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} alt="Twitter" />
          </a>
          <a className="App-social-link" href="https://docs.swapbased.finance/" target="_blank" rel="noopener noreferrer">
            <img src={gitbookIcon} alt="Gitbook" />
          </a>
          <a className="App-social-link" href="https://t.me/swapbased/" target="_blank" rel="noopener noreferrer">
            <img src={telegramIcon} alt="Telegram" />
          </a>
          <a className="App-social-link" href="https://discord.gg/rmpDfzsx8Q" target="_blank" rel="noopener noreferrer">
            <img src={discordIcon} alt="Discord" />
          </a>
        </div>
      </div>
    </div>
  );
}
