import React from "react";

import useSWR from "swr";

import {
  PLACEHOLDER_ACCOUNT,
  fetcher,
  formatKeyAmount,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
} from "../../Helpers";

import Vault from "../../abis/Vault.json";
import Reader from "../../abis/Reader.json";
import RewardReader from "../../abis/RewardReader.json";
import Token from "../../abis/Token.json";
import BlpManager from "../../abis/BlpManager.json";

import { useWeb3React } from "@web3-react/core";

import { useBasePrice, useTotalBaseSupply } from "../../Api";

import { getContract } from "../../Addresses";

export default function APRLabel({ chainId, label }) {
  let { active } = useWeb3React();

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const baseAddress = getContract(chainId, "BASE");
  const esBaseAddress = getContract(chainId, "ES_BASE");
  const bnBaseAddress = getContract(chainId, "BN_BASE");
  const blpAddress = getContract(chainId, "BLP");

  const stakedBaseTrackerAddress = getContract(chainId, "StakedBaseTracker");
  const bonusBaseTrackerAddress = getContract(chainId, "BonusBaseTracker");
  const feeBaseTrackerAddress = getContract(chainId, "FeeBaseTracker");

  const stakedBlpTrackerAddress = getContract(chainId, "StakedBlpTracker");
  const feeBlpTrackerAddress = getContract(chainId, "FeeBlpTracker");

  const blpManagerAddress = getContract(chainId, "BlpManager");

  const baseVesterAddress = getContract(chainId, "BaseVester");
  const blpVesterAddress = getContract(chainId, "BlpVester");
  const blpTokenAddress = getContract(chainId, "BlpToken");

  const vesterAddresses = [baseVesterAddress, blpVesterAddress];

  const walletTokens = [baseAddress, esBaseAddress, blpTokenAddress, blpAddress, stakedBaseTrackerAddress];
  const depositTokens = [
    baseAddress,
    esBaseAddress,
    stakedBaseTrackerAddress,
    bonusBaseTrackerAddress,
    bnBaseAddress,
    blpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedBaseTrackerAddress,
    stakedBaseTrackerAddress,
    bonusBaseTrackerAddress,
    feeBaseTrackerAddress,
    feeBaseTrackerAddress,
    feeBlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedBaseTrackerAddress,
    bonusBaseTrackerAddress,
    feeBaseTrackerAddress,
    stakedBlpTrackerAddress,
    feeBlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [`StakeV2:walletBalances:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(undefined, Reader, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [`StakeV2:depositBalances:${active}`, chainId, rewardReaderAddress, "getDepositBalances", PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(undefined, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(undefined, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedBaseSupply } = useSWR(
    [`StakeV2:stakedBaseSupply:${active}`, chainId, baseAddress, "balanceOf", stakedBaseTrackerAddress],
    {
      fetcher: fetcher(undefined, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, blpManagerAddress, "getAums"], {
    fetcher: fetcher(undefined, BlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: fetcher(undefined, Vault),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(undefined, Reader, [vesterAddresses]),
    }
  );

  const { basePrice } = useBasePrice(chainId, {}, active);

  let { total: baseSupply } = useTotalBaseSupply();

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedBaseSupply,
    basePrice,
    baseSupply
  );

  return <>{`${formatKeyAmount(processedData, label, 2, 2, true)}%`}</>;
}
