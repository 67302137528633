import { ApolloClient, InMemoryCache } from "@apollo/client";

export const chainlinkClient = createClient("https://api.studio.thegraph.com/query/67101/swapbased-perps-prices/version/latest"); // DONE

export const baseGraphClient = createClient("https://api.studio.thegraph.com/query/67101/swapbased-perps-core/version/latest"); // DONE

// All Positions
export const positionsGraphClient = createClient("https://api.thegraph.com/subgraphs/name/chimpydev/swapbased-perps-leaderboard"); // DONE

export const baseReferralsGraphClient = createClient("https://api.thegraph.com/subgraphs/name/chimpydev/swapbased-perps-referral"); // DONE


function createClient(uri) {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
}
