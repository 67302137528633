import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import Modal from "../../components/Modal/Modal";
import Checkbox from "../../components/Checkbox/Checkbox";
import Tooltip from "../../components/Tooltip/Tooltip";
import Footer from "../../Footer";
import earnedBigIcon from "../../img/earnlogo.svg";
import Vault from "../../abis/Vault.json";
import Reader from "../../abis/Reader.json";
import Vester from "../../abis/Vester.json";
import RewardRouter from "../../abis/RewardRouter.json";
import RewardReader from "../../abis/RewardReader.json";
import Token from "../../abis/Token.json";
import BlpManager from "../../abis/BlpManager.json";

import { ethers } from "ethers";
import {
  helperToast,
  bigNumberify,
  fetcher,
  formatAmount,
  formatKeyAmount,
  formatAmountFree,
  getChainName,
  expandDecimals,
  parseValue,
  approveTokens,
  getServerUrl,
  useLocalStorageSerializeKey,
  useChainId,
  BLP_DECIMALS,
  USD_DECIMALS,
  BASEBLP_DISPLAY_DECIMALS,
  BASIS_POINTS_DIVISOR,
  BASE,
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  getPageTitle,
} from "../../Helpers";
import { callContract, useBasePrice, useTotalBaseStaked, useTotalBaseSupply, useTotalBaseExcludedFromSupply } from "../../Api";
import { getConstant } from "../../Constants";

import useSWR from "swr";

import { getContract } from "../../Addresses";

import "./StakeV2.css";
import SEO from "../../components/Common/SEO";
import { BREAK } from "graphql";

const { AddressZero } = ethers.constants;

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    rewardRouterAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && stakingTokenAddress && [active, chainId, stakingTokenAddress, "allowance", account, farmAddress],
    {
      fetcher: fetcher(library, Token),
    }
  );

  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return "Enter An Amount";
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return "MAX AMOUNT EXCEEDED";
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: "Stake submitted!",
      failMsg: "Stake failed.",
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return `Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return `Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return "Staking...";
    }
    return "Stake";
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">Stake</div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              Max: {formatAmount(maxAmount, 18, 4, true)}
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    rewardRouterAddress,
    unstakeMethodName,
    multiplierPointsAmount,
    reservedAmount,
    bonusBaseInFeeBase,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);
  let burnAmount;

  if (
    multiplierPointsAmount &&
    multiplierPointsAmount.gt(0) &&
    amount &&
    amount.gt(0) &&
    bonusBaseInFeeBase &&
    bonusBaseInFeeBase.gt(0)
  ) {
    burnAmount = multiplierPointsAmount.mul(amount).div(bonusBaseInFeeBase);
  }

  const shouldShowReductionAmount = true;
  let rewardReductionBasisPoints;
  if (burnAmount && bonusBaseInFeeBase) {
    rewardReductionBasisPoints = burnAmount.mul(BASIS_POINTS_DIVISOR).div(bonusBaseInFeeBase);
  }

  const getError = () => {
    if (!amount) {
      return "Enter An Amount";
    }
    if (amount.gt(maxAmount)) {
      return "MAX AMOUNT EXCEEDED";
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: "Unstake submitted!",
      failMsg: "Unstake failed.",
      successMsg: "Unstake completed!",
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return "Unstaking...";
    }
    return "Unstake";
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">Unstake</div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              Max: {formatAmount(maxAmount, 18, 4, true)}
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{unstakingTokenSymbol}</div>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            Unstaking will burn&nbsp;
            <a href=" https://docs.swapbased.finance/rewards" target="_blank" rel="noopener noreferrer">
              {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
            </a>
            .&nbsp;
            {shouldShowReductionAmount && (
              <span>Boost %: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
            )}
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function VesterDepositModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    balance,
    escrowedBalance,
    averageStakedAmount,
    maxVestableAmount,
    library,
    stakeTokenLabel,
    reserveAmount,
    maxReserveAmount,
    vesterAddress,
    setPendingTxns,
  } = props;
  const [isDepositing, setIsDepositing] = useState(false);

  let amount = parseValue(value, 18);

  let nextReserveAmount = reserveAmount;

  let nextDepositAmount = escrowedBalance;
  if (amount) {
    nextDepositAmount = escrowedBalance.add(amount);
  }

  let additionalReserveAmount = bigNumberify(0);
  if (amount && averageStakedAmount && maxVestableAmount && maxVestableAmount.gt(0)) {
    nextReserveAmount = nextDepositAmount.mul(averageStakedAmount).div(maxVestableAmount);
    if (nextReserveAmount.gt(reserveAmount)) {
      additionalReserveAmount = nextReserveAmount.sub(reserveAmount);
    }
  }

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return "Enter An Amount";
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return "MAX AMOUNT EXCEEDED";
    }
    if (nextReserveAmount.gt(maxReserveAmount)) {
      return "INSUFFICIENT STAKED TOKENS";
    }
  };

  const onClickPrimary = () => {
    setIsDepositing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "deposit", [amount], {
      sentMsg: "Deposit submitted!",
      failMsg: "Deposit failed!",
      successMsg: "Deposited!",
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsDepositing(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isDepositing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isDepositing) {
      return "Depositing...";
    }
    return "Deposit";
  };

  return (
    <SEO title={getPageTitle("Earn")}>
      <div className="StakeModal">
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title} className="non-scrollable">
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">Deposit</div>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}
              >
                Max: {formatAmount(maxAmount, 18, 4, true)}
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="PositionEditor-token-symbol">esBASE</div>
            </div>
          </div>
          <div className="VesterDepositModal-info-rows">
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">Wallet</div>
              <div className="align-right">{formatAmount(balance, 18, 2, true)} esBASE</div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">Vault Capacity</div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(nextDepositAmount, 18, 2, true)} / ${formatAmount(
                    maxVestableAmount,
                    18,
                    2,
                    true
                  )}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <>
                        Vault Capacity for your Account
                        <br />
                        <br />
                        Deposited: {formatAmount(escrowedBalance, 18, 2, true)} esBASE
                        <br />
                        Max Capacity: {formatAmount(maxVestableAmount, 18, 2, true)} esBASE
                        <br />
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">Reserve Amount</div>
            <div className="align-right">
              <Tooltip
                handle={`${formatAmount(
                  reserveAmount && reserveAmount.gte(additionalReserveAmount) ? reserveAmount : additionalReserveAmount,
                  18,
                  2,
                  true
                )} / ${formatAmount(maxReserveAmount, 18, 2, true)}`}
                position="right-bottom"
                renderContent={() => {
                  return (
                    <>
                      Current Reserved: {formatAmount(reserveAmount, 18, 2, true)}
                      <br />
                      Additional reserve required: {formatAmount(additionalReserveAmount, 18, 2, true)}
                      <br />
                      {amount && nextReserveAmount.gt(maxReserveAmount) && (
                        <div>
                          <br />
                          You need a total of at least {formatAmount(nextReserveAmount, 18, 2, true)} {stakeTokenLabel}{" "}
                          to vest {formatAmount(amount, 18, 2, true)} esBASE.
                        </div>
                      )}
                    </>
                  );
                }}
              />
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      </div>
    </SEO>
  );
}

function VesterWithdrawModal(props) {
  const { isVisible, setIsVisible, chainId, title, library, vesterAddress, setPendingTxns } = props;
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const onClickPrimary = () => {
    setIsWithdrawing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "withdraw", [], {
      sentMsg: "Withdraw submitted.",
      failMsg: "Withdraw failed.",
      successMsg: "Withdrawn!",
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div>
        This will halt vesting and retrieve all tokens from reserve.
          <br />
          <br />
          Unvested BASE tokens will stay as BASE tokens.
          <br />
          <br />
          For claiming unvested BASE tokens without withdrawing, use the "CLAIM" button under the "Total Earnings" section.
          <br />
          <br />
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={isWithdrawing}>
            {!isWithdrawing && "Confirm Withdraw"}
            {isWithdrawing && "Confirming..."}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function CompoundModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    active,
    account,
    library,
    chainId,
    setPendingTxns,
    totalVesterRewards,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isCompounding, setIsCompounding] = useState(false);
  const [shouldClaimBase, setShouldClaimBase] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-base"],
    true
  );
  const [shouldStakeBase, setShouldStakeBase] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-base"],
    true
  );
  const [shouldClaimEsBase, setShouldClaimEsBase] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-es-base"],
    true
  );
  const [shouldStakeEsBase, setShouldStakeEsBase] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-es-base"],
    true
  );
  const [shouldStakeMultiplierPoints, setShouldStakeMultiplierPoints] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-multiplier-points"],
    true
  );
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-convert-weth"],
    true
  );

  const baseAddress = getContract(chainId, "BASE");
  const stakedBaseTrackerAddress = getContract(chainId, "StakedBaseTracker");

  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, baseAddress, "allowance", account, stakedBaseTrackerAddress],
    {
      fetcher: fetcher(library, Token),
    }
  );

  const needApproval = shouldStakeBase && tokenAllowance && totalVesterRewards && totalVesterRewards.gt(tokenAllowance);

  const isPrimaryEnabled = () => {
    return !isCompounding && !isApproving && !isCompounding;
  };

  const getPrimaryText = () => {
    if (isApproving) {
      return `Approving BASE...`;
    }
    if (needApproval) {
      return `Approve BASE`;
    }
    if (isCompounding) {
      return "Compounding...";
    }
    return "Compound";
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: baseAddress,
        spender: stakedBaseTrackerAddress,
        chainId,
      });
      return;
    }

    setIsCompounding(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimBase || shouldStakeBase,
        shouldStakeBase,
        shouldClaimEsBase || shouldStakeEsBase,
        shouldStakeEsBase,
        shouldStakeMultiplierPoints,
        shouldClaimWeth || shouldConvertWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: "Compound submitted!",
        failMsg: "Compound failed.",
        successMsg: "Compound completed!",
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsCompounding(false);
      });
  };

  const toggleShouldStakeBase = (value) => {
    if (value) {
      setShouldClaimBase(true);
    }
    setShouldStakeBase(value);
  };

  const toggleShouldStakeEsBase = (value) => {
    if (value) {
      setShouldClaimEsBase(true);
    }
    setShouldStakeEsBase(value);
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label="Compound Rewards">
        <div className="CompoundModal-menu">
          <div>
            <Checkbox isChecked={shouldStakeMultiplierPoints} setIsChecked={setShouldStakeMultiplierPoints}>
              Stake Multiplier Points
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimBase} setIsChecked={setShouldClaimBase} disabled={shouldStakeBase}>
              Claim unvested BASE
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeBase} setIsChecked={toggleShouldStakeBase}>
              Stake claimed BASE
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsBase} setIsChecked={setShouldClaimEsBase} disabled={shouldStakeEsBase}>
              Claim esBASE Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeEsBase} setIsChecked={toggleShouldStakeEsBase}>
              Stake claimed esBASE
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              Claim {wrappedTokenSymbol} Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button text-uppercase" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function ClaimModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    library,
    chainId,
    setPendingTxns,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isClaiming, setIsClaiming] = useState(false);
  const [shouldClaimBase, setShouldClaimBase] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-base"],
    true
  );
  const [shouldClaimEsBase, setShouldClaimEsBase] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-es-base"],
    true
  );
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-convert-weth"],
    true
  );

  const isPrimaryEnabled = () => {
    return !isClaiming;
  };

  const getPrimaryText = () => {
    if (isClaiming) {
      return `CLAIMING...`;
    }
    return "CLAIM";
  };

  const onClickPrimary = () => {
    setIsClaiming(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimBase,
        false, // shouldStakeBase
        shouldClaimEsBase,
        false, // shouldStakeEsBase
        false, // shouldStakeMultiplierPoints
        shouldClaimWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: "Claim submitted.",
        failMsg: "Claim failed.",
        successMsg: "Claim completed!",
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsClaiming(false);
      });
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label="Claim Rewards">
        <div className="CompoundModal-menu">
          <div>
            <Checkbox isChecked={shouldClaimBase} setIsChecked={setShouldClaimBase}>
              Claim unvested BASE
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsBase} setIsChecked={setShouldClaimEsBase}>
              Claim esBASE Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              Claim {wrappedTokenSymbol} Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default function StakeV2({ setPendingTxns, connectWallet }) {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const chainName = getChainName(chainId);

  const hasInsurance = false;

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");

  const [isVesterDepositModalVisible, setIsVesterDepositModalVisible] = useState(false);
  const [vesterDepositTitle, setVesterDepositTitle] = useState("");
  const [vesterDepositStakeTokenLabel, setVesterDepositStakeTokenLabel] = useState("");
  const [vesterDepositMaxAmount, setVesterDepositMaxAmount] = useState("");
  const [vesterDepositBalance, setVesterDepositBalance] = useState("");
  const [vesterDepositEscrowedBalance, setVesterDepositEscrowedBalance] = useState("");
  const [vesterDepositAverageStakedAmount, setVesterDepositAverageStakedAmount] = useState("");
  const [vesterDepositMaxVestableAmount, setVesterDepositMaxVestableAmount] = useState("");
  const [vesterDepositValue, setVesterDepositValue] = useState("");
  const [vesterDepositReserveAmount, setVesterDepositReserveAmount] = useState("");
  const [vesterDepositMaxReserveAmount, setVesterDepositMaxReserveAmount] = useState("");
  const [vesterDepositAddress, setVesterDepositAddress] = useState("");

  const [isVesterWithdrawModalVisible, setIsVesterWithdrawModalVisible] = useState(false);
  const [vesterWithdrawTitle, setVesterWithdrawTitle] = useState(false);
  const [vesterWithdrawAddress, setVesterWithdrawAddress] = useState("");

  const [isCompoundModalVisible, setIsCompoundModalVisible] = useState(false);
  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);

  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const baseAddress = getContract(chainId, "BASE");
  const esBaseAddress = getContract(chainId, "ES_BASE");
  const bnBaseAddress = getContract(chainId, "BN_BASE");
  const blpAddress = getContract(chainId, "BLP");

  const stakedBaseTrackerAddress = getContract(chainId, "StakedBaseTracker");
  const bonusBaseTrackerAddress = getContract(chainId, "BonusBaseTracker");
  const feeBaseTrackerAddress = getContract(chainId, "FeeBaseTracker");

  const stakedBlpTrackerAddress = getContract(chainId, "StakedBlpTracker");
  const feeBlpTrackerAddress = getContract(chainId, "FeeBlpTracker");

  const blpManagerAddress = getContract(chainId, "BlpManager");

  const stakedBaseDistributorAddress = getContract(chainId, "StakedBaseDistributor");
  const stakedBlpDistributorAddress = getContract(chainId, "StakedBlpDistributor");

  const baseVesterAddress = getContract(chainId, "BaseVester");
  const blpVesterAddress = getContract(chainId, "BlpVester");
  const blpTokenAddress = getContract(chainId, "BlpToken");

  const vesterAddresses = [baseVesterAddress, blpVesterAddress];

  const excludedEsBaseAccounts = [stakedBaseDistributorAddress, stakedBlpDistributorAddress];

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const walletTokens = [baseAddress, esBaseAddress, blpTokenAddress, blpAddress, stakedBaseTrackerAddress];
  const depositTokens = [
    baseAddress,
    esBaseAddress,
    stakedBaseTrackerAddress,
    bonusBaseTrackerAddress,
    bnBaseAddress,
    blpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedBaseTrackerAddress,
    stakedBaseTrackerAddress,
    bonusBaseTrackerAddress,
    feeBaseTrackerAddress,
    feeBaseTrackerAddress,
    feeBlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedBaseTrackerAddress,
    bonusBaseTrackerAddress,
    feeBaseTrackerAddress,
    stakedBlpTrackerAddress,
    feeBlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: fetcher(library, Reader, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: fetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedBaseSupply } = useSWR(
    [`StakeV2:stakedBaseSupply:${active}`, chainId, baseAddress, "balanceOf", stakedBaseTrackerAddress],
    {
      fetcher: fetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, blpManagerAddress, "getAums"], {
    fetcher: fetcher(library, BlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: fetcher(library, Vault),
    }
  );

  const { data: esBaseSupply } = useSWR(
    [`StakeV2:esBaseSupply:${active}`, chainId, readerAddress, "getTokenSupply", esBaseAddress],
    {
      fetcher: fetcher(library, Reader, [excludedEsBaseAccounts]),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, Reader, [vesterAddresses]),
    }
  );

  const { basePrice } = useBasePrice(chainId, { base: chainId === BASE ? library : undefined }, active);

  let { total: totalBaseSupply } = useTotalBaseSupply();

  let { base: baseBaseStaked, total: totalBaseStaked } = useTotalBaseStaked();
  let { total: excludedBaseSupply } = useTotalBaseExcludedFromSupply();

  let { total: baseSupply } = useTotalBaseSupply();

  const isBaseTransferEnabled = true;

  let esBaseSupplyUsd;
  if (esBaseSupply && basePrice) {
    esBaseSupplyUsd = esBaseSupply.mul(basePrice).div(expandDecimals(1, 18));
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedBaseSupply,
    basePrice,
    baseSupply
  );

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusBaseTrackerRewards && processedData.bnBaseInFeeBase) {
    multiplierPointsAmount = processedData.bonusBaseTrackerRewards.add(processedData.bnBaseInFeeBase);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }
  let totalRewardTokens;
  if (processedData && processedData.bnBaseInFeeBase && processedData.bonusBaseInFeeBase) {
    totalRewardTokens = processedData.bnBaseInFeeBase.add(processedData.bonusBaseInFeeBase);
  }

  let totalRewardTokensAndBlp;
  if (totalRewardTokens && processedData && processedData.blpBalance) {
    totalRewardTokensAndBlp = totalRewardTokens.add(processedData.blpBalance);
  }

  const bonusBaseInFeeBase = processedData ? processedData.bonusBaseInFeeBase : undefined;

  let stakedBaseSupplyUsd;
  if (!totalBaseStaked.isZero() && basePrice) {
    stakedBaseSupplyUsd = totalBaseStaked.mul(basePrice).div(expandDecimals(1, 18));
  }

  let totalSupplyUsd;
  if (totalBaseSupply && !totalBaseSupply.isZero() && excludedBaseSupply && !excludedBaseSupply.isZero() && basePrice) {
    totalSupplyUsd = (totalBaseSupply.sub(excludedBaseSupply)).mul(basePrice).div(expandDecimals(1, 18));
  }

  let maxUnstakeableBase = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.baseVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusBaseInFeeBase
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.baseVesterPairAmount);
    const stakedTokens = processedData.bonusBaseInFeeBase;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableBase = availableTokens.mul(stakedTokens).div(divisor);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showStakeBaseModal = () => {
    if (!isBaseTransferEnabled) {
      helperToast.error("BASE transfers not yet enabled");
      return;
    }

    setIsStakeModalVisible(true);
    setStakeModalTitle("Stake BASE");
    setStakeModalMaxAmount(processedData.baseBalance);
    setStakeValue("");
    setStakingTokenSymbol("BASE");
    setStakingTokenAddress(baseAddress);
    setStakingFarmAddress(stakedBaseTrackerAddress);
    setStakeMethodName("stakeBase");
  };

  const showStakeEsBaseModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle("Stake esBASE");
    setStakeModalMaxAmount(processedData.esBaseBalance);
    setStakeValue("");
    setStakingTokenSymbol("esBASE");
    setStakingTokenAddress(esBaseAddress);
    setStakingFarmAddress(AddressZero);
    setStakeMethodName("stakeEsBase");
  };

  const showBaseVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.baseVester.maxVestableAmount.sub(vestingData.baseVester.vestedAmount);
    if (processedData.esBaseBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esBaseBalance;
    }

    setIsVesterDepositModalVisible(true);
    setVesterDepositTitle("BASE Vault");
    setVesterDepositStakeTokenLabel("staked BASE + esBASE + Multiplier Points");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esBaseBalance);
    setVesterDepositEscrowedBalance(vestingData.baseVester.escrowedBalance);
    setVesterDepositMaxVestableAmount(vestingData.baseVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.baseVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.baseVester.pairAmount);
    setVesterDepositMaxReserveAmount(totalRewardTokens);
    setVesterDepositValue("");
    setVesterDepositAddress(baseVesterAddress);
  };

  const showBlpVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.blpVester.maxVestableAmount.sub(vestingData.blpVester.vestedAmount);
    if (processedData.esBaseBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esBaseBalance;
    }

    setIsVesterDepositModalVisible(true);
    setVesterDepositTitle("BLP Vault");
    setVesterDepositStakeTokenLabel("staked BLP");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esBaseBalance);
    setVesterDepositEscrowedBalance(vestingData.blpVester.escrowedBalance);
    setVesterDepositMaxVestableAmount(vestingData.blpVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.blpVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.blpVester.pairAmount);
    setVesterDepositMaxReserveAmount(processedData.blpBalance);
    setVesterDepositValue("");
    setVesterDepositAddress(blpVesterAddress);
  };

  const showBaseVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.baseVesterVestedAmount || vestingData.baseVesterVestedAmount.eq(0)) {
      helperToast.error("You have not deposited any tokens for vesting.");
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle("Withdraw from BASE Vault");
    setVesterWithdrawAddress(baseVesterAddress);
  };

  const showBlpVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.blpVesterVestedAmount || vestingData.blpVesterVestedAmount.eq(0)) {
      helperToast.error("You have not deposited any tokens for vesting.");
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle("Withdraw from BLP Vault");
    setVesterWithdrawAddress(blpVesterAddress);
  };

  const showUnstakeBaseModal = () => {
    if (!isBaseTransferEnabled) {
      helperToast.error("BASE transfers not yet enabled");
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle("Unstake BASE");
    let maxAmount = processedData.baseInStakedBase;
    if (
      processedData.baseInStakedBase &&
      vestingData &&
      vestingData.baseVesterPairAmount.gt(0) &&
      maxUnstakeableBase &&
      maxUnstakeableBase.lt(processedData.baseInStakedBase)
    ) {
      maxAmount = maxUnstakeableBase;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.baseVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("BASE");
    setUnstakeMethodName("unstakeBase");
  };

  const showUnstakeEsBaseModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle("Unstake esBASE");
    let maxAmount = processedData.esBaseInStakedBase;
    if (
      processedData.esBaseInStakedBase &&
      vestingData &&
      vestingData.baseVesterPairAmount.gt(0) &&
      maxUnstakeableBase &&
      maxUnstakeableBase.lt(processedData.esBaseInStakedBase)
    ) {
      maxAmount = maxUnstakeableBase;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.baseVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("esBASE");
    setUnstakeMethodName("unstakeEsBase");
  };

  const renderMultiplierPointsLabel = useCallback(() => {
    return "Multiplier Points APR";
  }, []);

  const renderMultiplierPointsValue = useCallback(() => {
    return (
      <Tooltip
        handle={`100.00%`}
        position="right-bottom"
        renderContent={() => {
          return (
            <>
              Boost your rewards with Multiplier Points.&nbsp;
              <a
                href=" https://docs.swapbased.finance/rewards#multiplier-points"
                rel="noreferrer"
                target="_blank"
                className="text-white"
              >
                More info
              </a>
              .
            </>
          );
        }}
      />
    );
  }, []);

  let earnMsg;
  if (totalRewardTokensAndBlp && totalRewardTokensAndBlp.gt(0)) {
    let baseAmountStr;
    if (processedData.baseInStakedBase && processedData.baseInStakedBase.gt(0)) {
      baseAmountStr = formatAmount(processedData.baseInStakedBase, 18, 2, true) + " BASE";
    }
    let esBaseAmountStr;
    if (processedData.esBaseInStakedBase && processedData.esBaseInStakedBase.gt(0)) {
      esBaseAmountStr = formatAmount(processedData.esBaseInStakedBase, 18, 2, true) + " esBASE";
    }
    let mpAmountStr;
    if (processedData.bonusBaseInFeeBase && processedData.bnBaseInFeeBase.gt(0)) {
      mpAmountStr = formatAmount(processedData.bnBaseInFeeBase, 18, 2, true) + " MP";
    }
    let blpStr;
    if (processedData.blpBalance && processedData.blpBalance.gt(0)) {
      blpStr = formatAmount(processedData.blpBalance, 18, 2, true) + " BLP";
    }
    const amountStr = [baseAmountStr, esBaseAmountStr, mpAmountStr, blpStr].filter((s) => s).join(", ");
    earnMsg = (
      <div>
        You earn {nativeTokenSymbol} rewards with {formatAmount(totalRewardTokensAndBlp, 18, 2, true)} tokens.
        <br />
        Tokens: {amountStr}.
      </div>
    );
  }

  return (
    <div className="StakeV2 Page page-layout">
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        hasMultiplierPoints={hasMultiplierPoints}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={unstakeMethodName}
        multiplierPointsAmount={multiplierPointsAmount}
        bonusBaseInFeeBase={bonusBaseInFeeBase}
      />
      <VesterDepositModal
        isVisible={isVesterDepositModalVisible}
        setIsVisible={setIsVesterDepositModalVisible}
        chainId={chainId}
        title={vesterDepositTitle}
        stakeTokenLabel={vesterDepositStakeTokenLabel}
        maxAmount={vesterDepositMaxAmount}
        balance={vesterDepositBalance}
        escrowedBalance={vesterDepositEscrowedBalance}
        averageStakedAmount={vesterDepositAverageStakedAmount}
        maxVestableAmount={vesterDepositMaxVestableAmount}
        reserveAmount={vesterDepositReserveAmount}
        maxReserveAmount={vesterDepositMaxReserveAmount}
        value={vesterDepositValue}
        setValue={setVesterDepositValue}
        library={library}
        vesterAddress={vesterDepositAddress}
        setPendingTxns={setPendingTxns}
      />
      <VesterWithdrawModal
        isVisible={isVesterWithdrawModalVisible}
        setIsVisible={setIsVesterWithdrawModalVisible}
        vesterAddress={vesterWithdrawAddress}
        chainId={chainId}
        title={vesterWithdrawTitle}
        library={library}
        setPendingTxns={setPendingTxns}
      />
      <CompoundModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isCompoundModalVisible}
        setIsVisible={setIsCompoundModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
      <ClaimModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
      <div className="Page-title-section mt-0">
        <div className="Page-title">Earn</div>
        <div className="Page-description">
          Stake{" "}
          <a href="https://docs.swapbased.finance/base/tokenomics" target="_blank" rel="noopener noreferrer">
            BASE
          </a>{" "}
          and{" "}
          <a href="https://docs.swapbased.finance/base/blp" target="_blank" rel="noopener noreferrer">
            BLP
          </a>{" "}
          to earn rewards.
        </div>
        {earnMsg && <div className="Page-description">{earnMsg}</div>}
      </div>
      <div className="StakeV2-content">
        <div className="StakeV2-cards">
          <div className="App-card StakeV2-gmx-card">
            <div className="App-card-title">BASE</div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">Price</div>
                <div>
                  {!basePrice && "..."}
                  {basePrice && (
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={"$" + formatAmount(basePrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <>
                          Price on Base: ${formatAmount(basePrice, USD_DECIMALS, 2, true)}
                        </>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Wallet</div>
                <div>
                  {formatKeyAmount(processedData, "baseBalance", 18, 2, true)} BASE ($
                  {formatKeyAmount(processedData, "baseBalanceUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Staked</div>
                <div>
                  {formatKeyAmount(processedData, "baseInStakedBase", 18, 2, true)} BASE ($
                  {formatKeyAmount(processedData, "baseInStakedBaseUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">APR</div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "baseAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <div className="Tooltip-row">
                            <span className="label">Escrowed BASE APR</span>
                            <span>{formatKeyAmount(processedData, "baseAprForEsBase", 2, 2, true)}%</span>
                          </div>
                          {(!processedData.baseBoostAprForNativeToken ||
                            processedData.baseBoostAprForNativeToken.eq(0)) && (
                            <div className="Tooltip-row">
                              <span className="label">{nativeTokenSymbol} APR</span>
                              <span>{formatKeyAmount(processedData, "baseAprForNativeToken", 2, 2, true)}%</span>
                            </div>
                          )}
                          {processedData.baseBoostAprForNativeToken && processedData.baseBoostAprForNativeToken.gt(0) && (
                            <div>
                              <br />
                              <div className="Tooltip-row">
                                <span className="label">{nativeTokenSymbol} Base APR</span>
                                <span>{formatKeyAmount(processedData, "baseAprForNativeToken", 2, 2, true)}%</span>
                              </div>
                              <div className="Tooltip-row">
                                <span className="label">{nativeTokenSymbol} Boosted APR</span>
                                <span>{formatKeyAmount(processedData, "baseBoostAprForNativeToken", 2, 2, true)}%</span>
                              </div>
                              <div className="Tooltip-row">
                                <span className="label">{nativeTokenSymbol} Total APR</span>
                                <span>
                                  {formatKeyAmount(processedData, "baseAprForNativeTokenWithBoost", 2, 2, true)}%
                                </span>
                              </div>
                              <br />
                              <div className="muted">The Boosted APR is from your staked Multiplier Points.</div>
                            </div>
                          )}
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Rewards</div>
                <div>
                  <Tooltip
                    handle={`$${formatKeyAmount(processedData, "totalBaseRewardsUsd", USD_DECIMALS, 2, true)}`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <div className="Tooltip-row">
                            <span className="label">
                              {nativeTokenSymbol} ({wrappedTokenSymbol})
                            </span>
                            <span>
                              {formatKeyAmount(processedData, "feeBaseTrackerRewards", 18, 4)} ($
                              {formatKeyAmount(processedData, "feeBaseTrackerRewardsUsd", USD_DECIMALS, 2, true)})
                            </span>
                          </div>
                          <div className="Tooltip-row">
                            <span className="label">Escrowed BASE</span>
                            <span>
                              {formatKeyAmount(processedData, "stakedBaseTrackerRewards", 18, 4)} ($
                              {formatKeyAmount(processedData, "stakedBaseTrackerRewardsUsd", USD_DECIMALS, 2, true)})
                            </span>
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">{renderMultiplierPointsLabel()}</div>
                <div>{renderMultiplierPointsValue()}</div>
              </div>
              <div className="App-card-row">
                <div className="label">Boost Percentage</div>
                <div>
                  <Tooltip
                    handle={`${formatAmount(processedData.boostBasisPoints, 2, 2, false)}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          You are earning {formatAmount(processedData.boostBasisPoints, 2, 2, false)}% more{" "}
                          {nativeTokenSymbol} rewards using {formatAmount(processedData.bnBaseInFeeBase, 18, 4, 2, true)}{" "}
                          Staked Multiplier Points.
                          <br />
                          <br />
                          Use the "Compound" button to stake your Multiplier Points.
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">Total Staked</div>
                <div>
                  {!totalBaseStaked && "..."}
                  {totalBaseStaked && (
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={
                        formatAmount(totalBaseStaked, 18, 0, true) +
                        " BASE" +
                        ` ($${formatAmount(stakedBaseSupplyUsd, USD_DECIMALS, 0, true)})`
                      }
                      renderContent={() => (
                        <>
                          Base: {formatAmount(baseBaseStaked, 18, 0, true)} BASE
                        </>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Total Supply</div>
                {(!totalBaseSupply && !excludedBaseSupply) && "..."}
                {(totalBaseSupply && excludedBaseSupply) && (
                  <div>
                    {formatAmount(totalBaseSupply ? totalBaseSupply.sub(excludedBaseSupply) : 0, 18, 0, true)} BASE ($
                    {formatAmount(totalSupplyUsd, USD_DECIMALS, 0, true)})
                  </div>
                )}
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-options">
                <a className="App-button-option App-card-option" href="https://swapbased.finance/3/swap">
                {/* <a className="App-button-option App-card-option" href="https://swapbased.finance/#/swap?outputCurrency=0xd07379a755A8f11B57610154861D694b2A0f615a"> */}
                  Buy BASE
                </a>
                {active && (
                  <button className="App-button-option App-card-option" onClick={() => showStakeBaseModal()}>
                    Stake
                  </button>
                )}
                {active && (
                  <button className="App-button-option App-card-option" onClick={() => showUnstakeBaseModal()}>
                    Unstake
                  </button>
                )}
                {active && (
                  <Link className="App-button-option App-card-option" to="/begin_account_transfer">
                    Transfer Account
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="App-card primary StakeV2-total-rewards-card">
            <div className="App-card-title">Total Rewards</div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  {nativeTokenSymbol} ({wrappedTokenSymbol})
                </div>
                <div>
                  {formatKeyAmount(processedData, "totalNativeTokenRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalNativeTokenRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">BASE</div>
                <div>
                  {formatKeyAmount(processedData, "totalVesterRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalVesterRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Escrowed BASE</div>
                <div>
                  {formatKeyAmount(processedData, "totalEsBaseRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalEsBaseRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Multiplier Points</div>
                <div>{formatKeyAmount(processedData, "bonusBaseTrackerRewards", 18, 4, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">Staked Multiplier Points</div>
                <div>{formatKeyAmount(processedData, "bnBaseInFeeBase", 18, 4, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">Total</div>
                <div>${formatKeyAmount(processedData, "totalRewardsUsd", USD_DECIMALS, 2, true)}</div>
              </div>
              <div className="App-card-bottom-placeholder">
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  {active && <button className="App-button-option App-card-option">Compound</button>}
                  {active && <button className="App-button-option App-card-option">Claim</button>}
                  {!active && (
                    <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                      Connect Wallet
                    </button>
                  )}
                </div>
              </div>
              <div className="App-card-bottom">
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  {active && (
                    <button
                      className="App-button-option App-card-option"
                      onClick={() => setIsCompoundModalVisible(true)}
                    >
                      Compound
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => setIsClaimModalVisible(true)}>
                      Claim
                    </button>
                  )}
                  {!active && (
                    <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                      Connect Wallet
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="App-card">
            <div className="App-card-title">BLP ({chainName})</div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">Price</div>
                <div>${formatKeyAmount(processedData, "blpPrice", USD_DECIMALS, 3, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">Wallet</div>
                <div>
                  {formatKeyAmount(processedData, "blpBalance", BLP_DECIMALS, 2, true)} BLP ($
                  {formatKeyAmount(processedData, "blpBalanceUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Staked</div>
                <div>
                  {formatKeyAmount(processedData, "blpBalance", BLP_DECIMALS, 2, true)} BLP ($
                  {formatKeyAmount(processedData, "blpBalanceUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">APR</div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "blpAprTotal", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <div className="Tooltip-row">
                            <span className="label">
                              {nativeTokenSymbol} ({wrappedTokenSymbol}) APR
                            </span>
                            <span>{formatKeyAmount(processedData, "blpAprForNativeToken", 2, 2, true)}%</span>
                          </div>
                          <div className="Tooltip-row">
                            <span className="label">Escrowed BASE APR</span>
                            <span>{formatKeyAmount(processedData, "blpAprForEsBase", 2, 2, true)}%</span>
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Rewards</div>
                <div>
                  <Tooltip
                    handle={`$${formatKeyAmount(processedData, "totalBlpRewardsUsd", USD_DECIMALS, 2, true)}`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <div className="Tooltip-row">
                            <span className="label">
                              {nativeTokenSymbol} ({wrappedTokenSymbol})
                            </span>
                            <span>
                              {formatKeyAmount(processedData, "feeBlpTrackerRewards", 18, 4)} ($
                              {formatKeyAmount(processedData, "feeBlpTrackerRewardsUsd", USD_DECIMALS, 2, true)})
                            </span>
                          </div>
                          <div className="Tooltip-row">
                            <span className="label">Escrowed BASE</span>
                            <span>
                              {formatKeyAmount(processedData, "stakedBlpTrackerRewards", 18, 4)} ($
                              {formatKeyAmount(processedData, "stakedBlpTrackerRewardsUsd", USD_DECIMALS, 2, true)})
                            </span>
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">Total Staked</div>
                <div>
                  {formatKeyAmount(processedData, "blpSupply", 18, 2, true)} BLP ($
                  {formatKeyAmount(processedData, "blpSupplyUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Total Supply</div>
                <div>
                  {formatKeyAmount(processedData, "blpSupply", 18, 2, true)} BLP ($
                  {formatKeyAmount(processedData, "blpSupplyUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-options">
                <Link className="App-button-option App-card-option" to="/buy_blp">
                  Buy BLP
                </Link>
                <Link className="App-button-option App-card-option" to="/buy_blp#redeem">
                  Sell BLP
                </Link>
                {/* {hasInsurance && (
                  <a
                    className="App-button-option App-card-option"
                    href="https://app.insurace.io/Insurance/Cart?id=124&referrer=545066382753150189457177837072918687520318754040"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Purchase Insurance
                  </a>
                )} */}
              </div>
            </div>
          </div>
          <div className="App-card">
            <div className="App-card-title">Escrowed BASE</div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">Price</div>
                <div>${formatAmount(basePrice, USD_DECIMALS, 2, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">Wallet</div>
                <div>
                  {formatKeyAmount(processedData, "esBaseBalance", 18, 2, true)} esBASE ($
                  {formatKeyAmount(processedData, "esBaseBalanceUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Staked</div>
                <div>
                  {formatKeyAmount(processedData, "esBaseInStakedBase", 18, 2, true)} esBASE ($
                  {formatKeyAmount(processedData, "esBaseInStakedBaseUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">APR</div>
                <div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(processedData, "baseAprTotalWithBoost", 2, 2, true)}%`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <>
                            <div className="Tooltip-row">
                              <span className="label">
                                {nativeTokenSymbol} ({wrappedTokenSymbol}) Base APR
                              </span>
                              <span>{formatKeyAmount(processedData, "baseAprForNativeToken", 2, 2, true)}%</span>
                            </div>
                            {processedData.bnBaseInFeeBase && processedData.bnBaseInFeeBase.gt(0) && (
                              <div className="Tooltip-row">
                                <span className="label">
                                  {nativeTokenSymbol} ({wrappedTokenSymbol}) Boosted APR
                                </span>
                                <span>{formatKeyAmount(processedData, "baseBoostAprForNativeToken", 2, 2, true)}%</span>
                              </div>
                            )}
                            <div className="Tooltip-row">
                              <span className="label">Escrowed BASE APR</span>
                              <span>{formatKeyAmount(processedData, "baseAprForEsBase", 2, 2, true)}%</span>
                            </div>
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">{renderMultiplierPointsLabel()}</div>
                <div>{renderMultiplierPointsValue()}</div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">Total Staked</div>
                <div>
                  {formatKeyAmount(processedData, "stakedEsBaseSupply", 18, 0, true)} esBASE ($
                  {formatKeyAmount(processedData, "stakedEsBaseSupplyUsd", USD_DECIMALS, 0, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Total Supply</div>
                <div>
                  {formatAmount(esBaseSupply, 18, 0, true)} esBASE (${formatAmount(esBaseSupplyUsd, USD_DECIMALS, 0, true)}
                  )
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-options">
                {active && (
                  <button className="App-button-option App-card-option" onClick={() => showStakeEsBaseModal()}>
                    Stake
                  </button>
                )}
                {active && (
                  <button className="App-button-option App-card-option" onClick={() => showUnstakeEsBaseModal()}>
                    Unstake
                  </button>
                )}
                {!active && (
                  <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="Page-title-section">
          <div className="Page-title">Vest</div>
          <div className="Page-description">
            Convert esBASE tokens to BASE tokens.
            <br />
            Please read the{" "}
            <a href="https://docs.swapbased.finance/base/rewards#vesting" target="_blank" rel="noopener noreferrer">
              vesting details
            </a>{" "}
            before using the vaults.
          </div>
        </div>
        <div>
          <div className="StakeV2-cards">
            <div className="App-card StakeV2-gmx-card">
              <div className="App-card-title">BASE Vault</div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">Staked Tokens</div>
                  <div>
                    <Tooltip
                      handle={formatAmount(totalRewardTokens, 18, 2, true)}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <>
                            {formatAmount(processedData.baseInStakedBase, 18, 2, true)} BASE
                            <br />
                            {formatAmount(processedData.esBaseInStakedBase, 18, 2, true)} esBASE
                            <br />
                            {formatAmount(processedData.bnBaseInFeeBase, 18, 2, true)} Multiplier Points
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">Reserved for Vesting</div>
                  <div>
                    {formatKeyAmount(vestingData, "baseVesterPairAmount", 18, 2, true)} /{" "}
                    {formatAmount(totalRewardTokens, 18, 2, true)}
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">Vesting Status</div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "baseVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                        vestingData,
                        "baseVesterVestedAmount",
                        18,
                        4,
                        true
                      )}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <>
                            {formatKeyAmount(vestingData, "baseVesterClaimSum", 18, 4, true)} tokens have been converted
                            to BASE from the&nbsp;
                            {formatKeyAmount(vestingData, "baseVesterVestedAmount", 18, 4, true)} esBASE deposited for
                            vesting.
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">Claimable</div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "baseVesterClaimable", 18, 4, true)} BASE`}
                      position="right-bottom"
                      renderContent={() =>
                        `${formatKeyAmount(
                          vestingData,
                          "baseVesterClaimable",
                          18,
                          4,
                          true
                        )} BASE tokens can be claimed, use the options under the Total Rewards section to claim them.`
                      }
                    />
                  </div>
                </div>
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  {!active && (
                    <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                      Connect Wallet
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => showBaseVesterDepositModal()}>
                      Deposit
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => showBaseVesterWithdrawModal()}>
                      Withdraw
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="App-card StakeV2-gmx-card">
              <div className="App-card-title">BLP Vault</div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">Staked Tokens</div>
                  <div>{formatAmount(processedData.blpBalance, 18, 2, true)} BLP</div>
                </div>
                <div className="App-card-row">
                  <div className="label">Reserved for Vesting</div>
                  <div>
                    {formatKeyAmount(vestingData, "blpVesterPairAmount", 18, 2, true)} /{" "}
                    {formatAmount(processedData.blpBalance, 18, 2, true)}
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">Vesting Status</div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "blpVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                        vestingData,
                        "blpVesterVestedAmount",
                        18,
                        4,
                        true
                      )}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <>
                            {formatKeyAmount(vestingData, "blpVesterClaimSum", 18, 4, true)} tokens have been converted
                            to BASE from the&nbsp;
                            {formatKeyAmount(vestingData, "blpVesterVestedAmount", 18, 4, true)} esBASE deposited for
                            vesting.
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">Claimable</div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "blpVesterClaimable", 18, 4, true)} BASE`}
                      position="right-bottom"
                      renderContent={() =>
                        `${formatKeyAmount(
                          vestingData,
                          "blpVesterClaimable",
                          18,
                          4,
                          true
                        )} BASE tokens can be claimed, use the options under the Total Rewards section to claim them.`
                      }
                    ></Tooltip>
                  </div>
                </div>
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  {!active && (
                    <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                      Connect Wallet
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => showBlpVesterDepositModal()}>
                      Deposit
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => showBlpVesterWithdrawModal()}>
                      Withdraw
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
      <Footer />
    </div>
  );
}
