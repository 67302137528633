import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import Modal from "../../components/Modal/Modal";
import Checkbox from "../../components/Checkbox/Checkbox";
import Tooltip from "../../components/Tooltip/Tooltip";
import Footer from "../../Footer";
import earnedBigIcon from "../../img/earnlogo.svg";
import Vault from "../../abis/Vault.json";
import Reader from "../../abis/Reader.json";
import Vester from "../../abis/Vester.json";
import RewardRouter from "../../abis/RewardRouter.json";
import RewardReader from "../../abis/RewardReader.json";
import Token from "../../abis/Token.json";
import BlpManager from "../../abis/BlpManager.json";
import BlpFarm from "../../abis/BlpFarm.json";
import BlpToken from "../../abis/BlpToken.json";
import { useTotalBlpTokenStakedFarm } from "../../Api";

import { ethers } from "ethers";
import {
  helperToast,
  bigNumberify,
  fetcher,
  formatAmount,
  formatKeyAmount,
  formatAmountFree,
  getChainName,
  expandDecimals,
  parseValue,
  approveTokens,
  useChainId,
  BLP_DECIMALS,
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  BASE,
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  getPageTitle,
} from "../../Helpers";
import { callContract, useBasePrice, useTotalBaseStaked, useTotalBlpTokenSupply } from "../../Api";
import { getConstant } from "../../Constants";

import useSWR from "swr";

import { getContract } from "../../Addresses";

import "./FarmBlp.css";

const { AddressZero } = ethers.constants;

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    blpFarmAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && stakingTokenAddress && [active, chainId, stakingTokenAddress, "allowance", account, blpFarmAddress],
    {
      fetcher: fetcher(library, Token),
    }
  );

  let amount = parseValue(value, 18);
  const needApproval = blpFarmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return "Enter An Amount";
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return "MAX AMOUNT EXCEEDED";
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: blpFarmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(blpFarmAddress, BlpFarm.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: "Stake submitted!",
      failMsg: "Stake failed.",
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return `Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return `Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return "Staking...";
    }
    return "Stake";
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">Stake</div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              Max: {formatAmount(maxAmount, 18, 4, true)}
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    blpFarmAddress,
    unstakeMethodName,
    reservedAmount,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);

  const getError = () => {
    if (!amount) {
      return "Enter An Amount";
    }
    if (amount.gt(maxAmount)) {
      return "MAX AMOUNT EXCEEDED";
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(blpFarmAddress, BlpFarm.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: "Unstake submitted!",
      failMsg: "Unstake failed.",
      successMsg: "Unstake completed!",
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return "Unstaking...";
    }
    return "Unstake";
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">Unstake</div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              Max: {formatAmount(maxAmount, 18, 4, true)}
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{unstakingTokenSymbol}</div>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function ClaimButton(props) {
  const {
    chainId,
    library,
    blpFarmAddress,
    setPendingTxns,
  } = props;
  const [isClaimingRewards, setIsClaimingRewards] = useState(false);

  const isClaimRewardsEnabled = () => {
    return !isClaimingRewards;
  };

  const getPrimaryTextClaimRewards = () => {
    if (isClaimingRewards) {
      return `Claiming...`;
    }
    return "Claim";
  };

  const onClickClaimRewards = () => {
    setIsClaimingRewards(true);

    const contract = new ethers.Contract(blpFarmAddress, BlpFarm.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "getReward",
      [],
      {
        sentMsg: "Claim submitted.",
        failMsg: "Claim failed.",
        successMsg: "Claim completed!",
        setPendingTxns,
      }
    )
    .then(async () => {})
      .finally(() => {
        setIsClaimingRewards(false);
      });
  };


  return (
    <button className="App-button-option App-card-option" onClick={() => onClickClaimRewards()} disabled={!isClaimRewardsEnabled()}>
      {getPrimaryTextClaimRewards()}
    </button>
  );
}

export default function FarmBlp({ setPendingTxns, connectWallet }) {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const baseAddress = getContract(chainId, "BASE");
  const esBaseAddress = getContract(chainId, "ES_BASE");
  const bnBaseAddress = getContract(chainId, "BN_BASE");
  const blpAddress = getContract(chainId, "BLP");
  const blpTokenAddress = getContract(chainId, "BlpToken");
  const blpFarmAddress = getContract(chainId, "BlpFarm");

  const stakedBaseTrackerAddress = getContract(chainId, "StakedBaseTracker");
  const bonusBaseTrackerAddress = getContract(chainId, "BonusBaseTracker");
  const feeBaseTrackerAddress = getContract(chainId, "FeeBaseTracker");

  const stakedBlpTrackerAddress = getContract(chainId, "StakedBlpTracker");
  const feeBlpTrackerAddress = getContract(chainId, "FeeBlpTracker");

  const blpManagerAddress = getContract(chainId, "BlpManager");

  const baseVesterAddress = getContract(chainId, "BaseVester");
  const blpVesterAddress = getContract(chainId, "BlpVester");

  const vesterAddresses = [baseVesterAddress, blpVesterAddress];

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const walletTokens = [baseAddress, esBaseAddress, blpTokenAddress, blpAddress, stakedBaseTrackerAddress];
  const depositTokens = [
    baseAddress,
    esBaseAddress,
    stakedBaseTrackerAddress,
    bonusBaseTrackerAddress,
    bnBaseAddress,
    blpTokenAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedBaseTrackerAddress,
    stakedBaseTrackerAddress,
    bonusBaseTrackerAddress,
    feeBaseTrackerAddress,
    feeBaseTrackerAddress,
    feeBlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedBaseTrackerAddress,
    bonusBaseTrackerAddress,
    feeBaseTrackerAddress,
    stakedBlpTrackerAddress,
    feeBlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `FarmBlp:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: fetcher(library, Reader, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `FarmBlp:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: fetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`FarmBlp:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  // DONE
  const { data: stakedBlpSupply } = useSWR(
    [`FarmBlp:stakedBlpSupply:${active}`, chainId, blpTokenAddress, "balanceOf", blpFarmAddress],
    {
      fetcher: fetcher(library, Token),
    }
  );

  const { data: lastAddedLiq } = useSWR(
    [`FarmBlp:stakedBlpSupply:${active}`, chainId, blpTokenAddress, "lastAddedAt", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, BlpToken),
    }
  );

    // DONE
    const { data: userStaked } = useSWR(
      [`FarmBlp:userStaked:${active}`, chainId, blpFarmAddress, "balanceOf", account || PLACEHOLDER_ACCOUNT],
      {
        fetcher: fetcher(library, BlpFarm),
      }
    );

  const { data: aums } = useSWR([`FarmBlp:getAums:${active}`, chainId, blpManagerAddress, "getAums"], {
    fetcher: fetcher(library, BlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`FarmBlp:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: fetcher(library, Vault),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`FarmBlp:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, Reader, [vesterAddresses]),
    }
  );

  const { basePrice } = useBasePrice(chainId, { base: chainId === BASE ? library : undefined }, active);

  let { total: totalBlpTokenSupply } = useTotalBlpTokenSupply();

  let { totalStakedFarm: totalBlpTokenStakedFarm, totalStakedFarmDollars: totalBlpTokenStakedFarmDollars, farmAprToken1: farmAprToken1, farmAprToken2: farmAprToken2, earnedToken1: earnedToken1, earnedToken2: earnedToken2 } = useTotalBlpTokenStakedFarm(account || PLACEHOLDER_ACCOUNT);

  let { total: blpTokenSupply } = useTotalBlpTokenSupply();

  const isBaseTransferEnabled = true;

  const userCanStake = lastAddedLiq ? Number(lastAddedLiq.toString()) + 900 < Date.now() / 1000 ? true : false : false

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedBlpSupply,
    basePrice,
    blpTokenSupply
  );

  let totalRewardTokens;
  if (processedData && processedData.bnBaseInFeeBase && processedData.bonusBaseInFeeBase) {
    totalRewardTokens = processedData.bnBaseInFeeBase.add(processedData.bonusBaseInFeeBase);
  }

  let totalRewardTokensAndBlp;
  if (totalRewardTokens && processedData && processedData.blpBalance) {
    totalRewardTokensAndBlp = totalRewardTokens.add(processedData.blpBalance);
  }

  let totalSupplyUsd;
  if (totalBlpTokenSupply && !totalBlpTokenSupply.isZero() && basePrice) {
    totalSupplyUsd = totalBlpTokenSupply.mul(basePrice).div(expandDecimals(1, 18));
  }

  let maxUnstakeableBase = bigNumberify(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showStakeBlpTokenModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle("Stake BLP");
    setStakeModalMaxAmount(blpTokensWallet);
    setStakeValue("");
    setStakingTokenSymbol("BLP");
    setStakingTokenAddress(blpTokenAddress);
    setStakeMethodName("deposit");
  };

  const showUnstakeBlpTokenModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle("Unstake BLP");
    let maxAmount = blpTokensStaked;
    if (
      blpTokensStaked &&
      vestingData &&
      vestingData.baseVesterPairAmount.gt(0) &&
      maxUnstakeableBase &&
      maxUnstakeableBase.lt(blpTokensStaked)
    ) {
      maxAmount = maxUnstakeableBase;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.baseVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("BLP");
    setUnstakeMethodName("withdraw");
  };

  let earnMsg;
  if (totalRewardTokensAndBlp && totalRewardTokensAndBlp.gt(0)) {
    let baseAmountStr;
    if (processedData.baseInStakedBase && processedData.baseInStakedBase.gt(0)) {
      baseAmountStr = formatAmount(processedData.baseInStakedBase, 18, 2, true) + " BASE";
    }
    let esBaseAmountStr;
    if (processedData.esBaseInStakedBase && processedData.esBaseInStakedBase.gt(0)) {
      esBaseAmountStr = formatAmount(processedData.esBaseInStakedBase, 18, 2, true) + " esBASE";
    }
    let mpAmountStr;
    if (processedData.bonusBaseInFeeBase && processedData.bnBaseInFeeBase.gt(0)) {
      mpAmountStr = formatAmount(processedData.bnBaseInFeeBase, 18, 2, true) + " MP";
    }
    let blpStr;
    if (processedData.blpBalance && processedData.blpBalance.gt(0)) {
      blpStr = formatAmount(processedData.blpBalance, 18, 2, true) + " BLP";
    }
    const amountStr = [baseAmountStr, esBaseAmountStr, mpAmountStr, blpStr].filter((s) => s).join(", ");
    earnMsg = (
      <div>
        You earn {nativeTokenSymbol} rewards with {formatAmount(totalRewardTokensAndBlp, 18, 2, true)} tokens.
        <br />
        Tokens: {amountStr}.
      </div>
    );
  }

  const { data: blpTokensWallet } = useSWR(
    [`StakeV2:blpTokensWallet:${active}`, chainId, blpTokenAddress, "balanceOf", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, Token),
    }
  );

  const { data: blpTokensStaked } = useSWR(
    [`StakeV2:blpTokensStaked:${active}`, chainId, blpTokenAddress, "balanceOf", blpFarmAddress],
    {
      fetcher: fetcher(library, BlpFarm),
    }
  );

  return (
    <div className="FarmBlp page-layout default-container">
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        blpFarmAddress={blpFarmAddress}
        stakeMethodName={stakeMethodName}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={userStaked}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        blpFarmAddress={blpFarmAddress}
        unstakeMethodName={unstakeMethodName}
      />
      <div className="section-title-content mt-0">
        <div className="Page-title">Earn</div>
        <div className="Page-description">
          Farm with{" "}
          <a href="https://docs.swapbased.finance/" target="_blank" rel="noopener noreferrer">
            BLP
          </a>{" "}
          to earn rewards.
        </div>
        {earnMsg && <div className="Page-description">{earnMsg}</div>}
      </div>
      <div className="FarmBlp-content">
        <div className="FarmBlp-cards">
          <div className="App-card FarmBlp-gmx-card">
            <div className="App-card-title">BLP</div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
            <div className="App-card-row">
                <div className="label">Price</div>
                <div>${formatKeyAmount(processedData, "blpPrice", USD_DECIMALS, 3, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">Wallet</div>
                <div>
                  {formatAmount(blpTokensWallet, BLP_DECIMALS, 2, true)} BLP ($
                  {(Number(formatAmount(blpTokensWallet, BLP_DECIMALS, 2, true)) * Number(formatKeyAmount(processedData, "blpPrice", USD_DECIMALS, 3, true))).toLocaleString("en-US")})
                  {/* {Number(formatKeyAmount(processedData, "blpBalanceUsd", USD_DECIMALS, 2, true)) * Number(formatKeyAmount(processedData, "blpPrice", USD_DECIMALS, 3, true))}) */}
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">You Staked</div>
                <div>
                  {formatAmount(userStaked, BLP_DECIMALS, 2, true)} BLP ($
                    {(Number(formatAmount(userStaked, BLP_DECIMALS, 2, false)) * Number(formatKeyAmount(processedData, "blpPrice", USD_DECIMALS, 3, false))).toLocaleString("en-US")})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">APR</div>
                <div>
                  <Tooltip
                    handle={`${farmAprToken1 === undefined || farmAprToken2 === undefined ? 0 : (Number(formatAmount(farmAprToken1, 18, 2, false)) + Number(formatAmount(farmAprToken2, 18, 2, false))).toLocaleString("en-US")}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          {/* {(!farmAprToken1 ||
                            farmAprToken1.eq(0)) && ( */}
                            <div className="Tooltip-row">
                              <span className="label">BASE APR</span>
                              <span>{formatAmount(farmAprToken1, 18, 2, true)}%</span>
                            </div>
                          {/* )}
                          {(!farmAprToken2 ||
                            farmAprToken2.eq(0)) && ( */}
                            <div className="Tooltip-row">
                              <span className="label">xBASE APR</span>
                              <span>{formatAmount(farmAprToken2, 18, 2, true)}%</span>
                            </div>
                          {/* )} */}
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Rewards</div>
                <div>
                  <Tooltip
                    handle={`${earnedToken1 === undefined || earnedToken2 === undefined ? 0 : (Number(formatAmount(earnedToken1, 18, 6, false)) + Number(formatAmount(earnedToken2, 18, 6, false))).toLocaleString("en-US")}`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <div className="Tooltip-row">
                            <span className="label">
                              BASE
                            </span>
                            <span>
                              {formatAmount(earnedToken1, 18, 6, true)} ($
                              {(Number(formatAmount(earnedToken1, 18, 6, false)) * Number(formatAmount(basePrice, 30, 6, false))).toLocaleString("en-US")})
                            </span>
                          </div>
                          <div className="Tooltip-row">
                            <span className="label">
                              xBASE
                            </span>
                            <span>
                              {formatAmount(earnedToken2, 18, 4, true)} ($
                                {(Number(formatAmount(earnedToken2, 18, 6, false)) * Number(formatAmount(basePrice, 30, 6, false))).toLocaleString("en-US")})
                            </span>
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">Total Staked</div>
                <div>
                  {!totalBlpTokenStakedFarm && "..."}
                  {totalBlpTokenStakedFarm && (
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={
                        formatAmount(totalBlpTokenStakedFarm, 18, 2, true) +
                        " BLP" +
                        ` ($${formatAmount(totalBlpTokenStakedFarmDollars, 18, 2, true)})`
                      }
                      renderContent={() => (
                        <>
                          {formatAmount(totalBlpTokenStakedFarm, 18, 2, true)} BLP
                        </>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-options">
                {active && (
                  <button disabled={!userCanStake || !lastAddedLiq} className="App-button-option App-card-option" onClick={userCanStake ? () => showStakeBlpTokenModal() : null}>
                    Stake {lastAddedLiq ? userCanStake ? null : "(in cooldown)" : null}
                  </button>
                )}
                {active && (
                  <button className="App-button-option App-card-option" onClick={() => showUnstakeBlpTokenModal()}>
                    Unstake
                  </button>
                )}
                {active && (
                  <ClaimButton
                    chainId={chainId}
                    library={library}
                    blpFarmAddress={blpFarmAddress}
                    setPendingTxns={setPendingTxns}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <i>Note: There is a minimum 15 minutes holding time after adding liquidity.</i>
      <Footer />
    </div>
  );
}