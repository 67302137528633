const CONTRACTS = {
  8453: {
    // base
    Vault: "0x210b49f74040A385840a3276E81bA9010954d064",
    Router: "0xd72d5Ad987BD875FD43Efee6258129064972Ed74",
    VaultReader: "0x02a73D670f98b6A7d48F32577F3B9A2d2A7BBCb8",
    Reader: "0xc7aDbCf46Da2F2b9Fd575aDaAEAb39A5669c497e",
    BlpManager: "0x1142c2dB68E9E87B7358e129A40771dBca0A34da",
    RewardRouter: "0x265a30f14E34248567B5B0a83978C38dF38D0C60",
    RewardReader: "0xE05986f84B4d74dD13f2743eA751c7E1E83076eb",
    BLP: "0x6ECCA85569F1101bb54Fcd71d81984084096d837",
    BASE: "0xd07379a755A8f11B57610154861D694b2A0f615a", // done
    ES_BASE: "0xdE30EF700a3B9Ce0C2b1150D6462613533a5f2A7",
    BN_BASE: "0x1081DF8cd3560894191ef26E0AC078d8d2f041C1",
    BlpToken: "0x2168eb98C6D416Afb85E7beef5abDc4FB4177dfE",
    BlpFarm: "0xe17E46780461bB7E7397fB36aC6d03A307055c4e",
    USDB: "0x025AaCB4A93626B49C1B43c0AfA4BD9e81938E9A",
    StakedBaseTracker: "0x3bb51E85130c03455B408E77b0aff1FdC669D5aD",
    BonusBaseTracker: "0x1ef74F173948a0C000caa4bcE876a52FDFaE6949",
    FeeBaseTracker: "0xaE7093DDc18C1A8ab56CAD2c9d94eeC2DCe29b88",
    FeeBlpTracker: "0x772Defa308F27cCF8FDBEDa4860fE617846bc383",
    StakedBlpTracker: "0x73c7ce996503109A111CB71AFD00889390Fe7708",
    StakedBaseDistributor: "0x1376d6Ba696F2C201Cf5b44ee2B7751E55eC90b6",
    StakedBlpDistributor: "0x16CEDC246f2Dd4B01E28bAbD3D0e9Fc4724bC4c4",
    BaseVester: "0x7e9Fb800edee21561cCDC03051225C077095D29d",
    BlpVester: "0x1528935dC59D3B58644e8Ffb1ae0d0068D493f08",
    OrderBook: "0x1821e34C08888309546D156C378CEB848278D352",
    PositionManager: "0xa3d25D0037E50B6526678dA38c7211FcC519C1f0",
    OrderBookReader: "0xDFe54B029c787F6418F9088590E81981dc0EcD88",
    PositionRouter: "0x11b09dBB0b743E0963c80EE43746e85804aCb79e",
    ReferralStorage: "0x43B4345A0A5d64142220AA44D22D3043cB5448C5",
    ReferralReader: "0xD365c702D5082F10065cbA324c2D0B10796174ca",
    UniswapBaseUsdcPool: "0x153bA6ADa7143E621c6A85FA44F2EAc29a364D4A",
    // UniswapBaseUsdcPool: "0xe21aA505CFC43AfA4AA2B6cAcd64afd51Be50904",
    UniswapBaseUsdcPoolUsdcToken: "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
    // UniswapBaseUsdcPoolUsdcToken: "0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf",
    UniswapBaseWethPool: "0x5554419CCd0293d9383901F461C7C3E0c66E925F",
    NATIVE_TOKEN: "0x4200000000000000000000000000000000000006",
    xBASE: "0xeF94C12Ba5Bb2bF56E19BABfa56880487FEA6E82",
  },
};

const tryRequire = (path) => {
  try {
    return require(`${path}`);
  } catch (err) {
    return undefined;
  }
};
const devAddresses = tryRequire("./development.Addresses.js");

export function getContract(chainId, name) {
  const contracts = process.env.NODE_ENV === "development" && devAddresses ? devAddresses.CONTRACTS : CONTRACTS;

  if (!contracts[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }
  if (!contracts[chainId][name]) {
    throw new Error(`Unknown constant "${name}" for chainId ${chainId}`);
  }
  return contracts[chainId][name];
}

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

export const BASE_MULTISIG_ADDRESS = "0xfD5844867387Cecc3A0393b3e0BE32479Ea9e61a";
export const MVD_TREASURY_ADDRESS = "0xfD5844867387Cecc3A0393b3e0BE32479Ea9e61a";
