import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import baseBigIcon from "../../img/swapbased-logo.png";
import blpBigIcon from "../../img/logo-blp.svg";

import { BASE,switchNetwork, useChainId } from "../../Helpers";

import { useWeb3React } from "@web3-react/core";

import APRLabel from "../APRLabel/APRLabel";

export default function TokenCard() {
  const { chainId } = useChainId();
  const { active } = useWeb3React();

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  return (
    <div className="Home-token-card-options ">
      <div className="Home-token-card-option borderradius">
        <div className="Home-token-card-option-icon">
          <img src={baseBigIcon} alt="baseBigIcon" style={{width: '40px', height: '40px'}}/> BASE
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-title">
            <p>Our main token BASE, when locked into xBASE earns real yield from the protocol fees collected.</p>
            {/* Acquires 30% of the earnings the platform generates. */}
          </div>
          {/* <div className="Home-token-card-option-apr">
            Base APR: <APRLabel chainId={BASE} label="baseAprTotal" />
          </div> */}
          <div className="Home-token-card-option-action">
            <div className="buy">
              <a href="https://swapbased.finance/#/swap?currency1=0xd07379a755A8f11B57610154861D694b2A0f615a" className="default-btnhome">
              {/* <Link to="/buy_base" className="default-btnhome" onClick={() => changeNetwork(BASE)}> */}
              {"Buy BASE"}
              </a>
            </div>
            <a
              href="https://docs.swapbased.finance/"
              target="_blank"
              rel="noreferrer"
              className="default-btnhome read-more"
            >
              Read More
            </a>
          </div>
        </div>
      </div>
      <div className="Home-token-card-option borderradius">
        <div className="Home-token-card-option-icon ">
          <img src={blpBigIcon} alt="blpBigIcon" style={{width: '40px', height: '40px'}} /> BLP
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-title">
          <p>Provide liquidity to BLPs which acts like a vault share & further stake it to earn real yield from protocol generated fees.</p>
          {/* The platform's liquidity token, BLP, receives 60% of the fees collected. */}
          </div>
          {/* <div className="Home-token-card-option-apr">
            Base APR: <APRLabel chainId={BASE} label="blpAprTotal" key="BASE" />
          </div> */}
          <div className="Home-token-card-option-action">
            <div className="buy">
              <Link to="/liquidity" className="default-btnhome" onClick={() => changeNetwork(BASE)}>
                Mint BLP
              </Link>
              {/*
              <Link to="/buy_blp#redeem" className="default-btnhome" onClick={() => changeNetwork(BASE)}>
                Redeem
              </Link>
              */}
            </div>
            <a
              href="https://docs.swapbased.finance"
              target="_blank"
              rel="noreferrer"
              className="default-btnhome read-more"
            >
              Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
