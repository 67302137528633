let eventsData = [
  {
    id: "base-trade-launch",
    title: "Base Launch",
    isActive: true,
    validTill: "21/12/2022 12:00:00 PM",
    bodyText: "The Base is now live!",
    buttons: [
      {
        text: "Read More",
        link: "https://docs.swapbased.finance/",
      },
      {
        text: "Buy BASE",
        link: "/buy",
      },
      {
        text: "Mint",
        link: "buy_blp",
      },
      {
        text: "Trade Now",
        link: "/trade",
      },
    ],
  },
];
export default eventsData;
