import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { ethers } from "ethers";

import Tab from "../Tab/Tab";
import cx from "classnames";

import { getToken, getTokens, getWhitelistedTokens, getWrappedToken, getNativeToken } from "../../data/Tokens";
import { getContract } from "../../Addresses";
import {
  helperToast,
  useLocalStorageByChainId,
  getTokenInfo,
  // getChainName,
  useChainId,
  expandDecimals,
  fetcher,
  bigNumberify,
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  // formatDateTime,
  getBuyBlpToAmount,
  getBuyBlpFromAmount,
  getSellBlpFromAmount,
  getSellBlpToAmount,
  parseValue,
  approveTokens,
  getUsd,
  adjustForDecimals,
  BLP_DECIMALS,
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  BLP_COOLDOWN_DURATION,
  SECONDS_PER_YEAR,
  USDB_DECIMALS,
  BASE,
  PLACEHOLDER_ACCOUNT,
  BASEBLP_DISPLAY_DECIMALS,
} from "../../Helpers";

import { callContract, useBasePrice, useInfoTokens } from "../../Api";

import TokenSelector from "../Exchange/TokenSelector";
import BuyInputSection from "../BuyInputSection/BuyInputSection";
import Tooltip from "../Tooltip/Tooltip";

import Reader from "../../abis/Reader.json";
import RewardReader from "../../abis/RewardReader.json";
import Vault from "../../abis/Vault.json";
import BlpManager from "../../abis/BlpManager.json";
import RewardTracker from "../../abis/RewardTracker.json";
import Vester from "../../abis/Vester.json";
import RewardRouter from "../../abis/RewardRouter.json";
import Token from "../../abis/Token.json";

import blp24Icon from "../../img/logo-blp.svg";
import blp40Icon from "../../img/logo-blp.svg";
import arrowIcon from "../../img/ic_convert_down.svg";

import base16Icon from "../../img/logo-basechain.webp";

import "./BlpSwap.css";
import AssetDropdown from "../../views/Dashboard/AssetDropdown";

const { AddressZero } = ethers.constants;

function getStakingData(stakingInfo) {
  if (!stakingInfo || stakingInfo.length === 0) {
    return;
  }

  const keys = ["stakedBlpTracker", "feeBlpTracker"];
  const data = {};
  const propsLength = 5;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = {
      claimable: stakingInfo[i * propsLength],
      tokensPerInterval: stakingInfo[i * propsLength + 1],
      averageStakedAmounts: stakingInfo[i * propsLength + 2],
      cumulativeRewards: stakingInfo[i * propsLength + 3],
      totalSupply: stakingInfo[i * propsLength + 4],
    };
  }

  return data;
}

export default function BlpSwap(props) {
  const { savedSlippageAmount, isBuying, setPendingTxns, connectWallet, setIsBuying } = props;
  const history = useHistory();
  const swapLabel = isBuying ? "Mint" : "Redeem";
  const tabLabel = isBuying ? "Mint" : "Redeem";
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  // const chainName = getChainName(chainId)
  const tokens = getTokens(chainId);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const [swapValue, setSwapValue] = useState("");
  const [blpValue, setBlpValue] = useState("");
  const [swapTokenAddress, setSwapTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${swapLabel}-swap-token-address`,
    AddressZero
  );
  const [isApproving, setIsApproving] = useState(false);
  const [isApproving2, setIsApproving2] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isWaitingForApproval2, setIsWaitingForApproval2] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorOnSwapAmount, setAnchorOnSwapAmount] = useState(true);
  const [feeBasisPoints, setFeeBasisPoints] = useState("");

  const readerAddress = getContract(chainId, "Reader");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const stakedBlpTrackerAddress = getContract(chainId, "StakedBlpTracker");
  const feeBlpTrackerAddress = getContract(chainId, "FeeBlpTracker");
  const usdbAddress = getContract(chainId, "USDB");
  const baseAddress = getContract(chainId, "BASE");
  const blpAddress = getContract(chainId, "BLP");
  const blpManagerAddress = getContract(chainId, "BlpManager");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const tokensForBalanceAndSupplyQuery = [stakedBlpTrackerAddress, usdbAddress];

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(
    [`BlpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, Reader, [tokenAddresses]),
    }
  );

  const { data: balancesAndSupplies } = useSWR(
    [
      `BlpSwap:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: fetcher(library, Reader, [tokensForBalanceAndSupplyQuery]),
    }
  );
  const tokensForSupplyQuery = [baseAddress, blpAddress, usdbAddress];
  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: fetcher(library, Reader, [tokensForSupplyQuery]),
    }
  );

  const { data: aums } = useSWR([`BlpSwap:getAums:${active}`, chainId, blpManagerAddress, "getAums"], {
    fetcher: fetcher(library, BlpManager),
  });

  const { data: totalTokenWeights } = useSWR(
    [`BlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: fetcher(library, Vault),
    }
  );

  const tokenAllowanceAddress = swapTokenAddress === AddressZero ? nativeTokenAddress : swapTokenAddress;
  const { data: tokenAllowance } = useSWR(
    [active, chainId, tokenAllowanceAddress, "allowance", account || PLACEHOLDER_ACCOUNT, blpManagerAddress],
    {
      fetcher: fetcher(library, Token),
    }
  );

  const { data: tokenAllowanceBlpToken } = useSWR(
    [active, chainId, "0x2168eb98C6D416Afb85E7beef5abDc4FB4177dfE", "allowance", account || PLACEHOLDER_ACCOUNT, "0x265a30f14E34248567B5B0a83978C38dF38D0C60"],
    {
      fetcher: fetcher(library, Token),
    }
  );

  const { data: lastPurchaseTime } = useSWR(
    [`BlpSwap:lastPurchaseTime:${active}`, chainId, blpManagerAddress, "lastAddedAt", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, BlpManager),
    }
  );

  // const { data: blpBalance } = useSWR(
  //   [`BlpSwap:blpBalance:${active}`, chainId, feeBlpTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
  //   {
  //     fetcher: fetcher(library, RewardTracker),
  //   }
  // );

  const { data: blpBalance } = useSWR(
    [`BlpSwap:blpBalance:${active}`, chainId, "0x2168eb98C6D416Afb85E7beef5abDc4FB4177dfE", "balanceOf", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, Token),
    }
  );

  const blpVesterAddress = getContract(chainId, "BlpVester");
  const { data: reservedAmount } = useSWR(
    [`BlpSwap:reservedAmount:${active}`, chainId, blpVesterAddress, "pairAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, Vester),
    }
  );

  const { basePrice } = useBasePrice(chainId, { base: chainId === BASE ? library : undefined }, active);

  const rewardTrackersForStakingInfo = [stakedBlpTrackerAddress, feeBlpTrackerAddress];
  const { data: stakingInfo } = useSWR(
    [`BlpSwap:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const stakingData = getStakingData(stakingInfo);

  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(BLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindow = redemptionTime && parseInt(Date.now() / 1000) < redemptionTime;

  const blpSupply = totalSupplies ? totalSupplies[3] : bigNumberify(0);
  // const blpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);
  const usdbSupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0);
  // OLD METHOD FOR BLP PRICE CALC
  let aum;
  if (aums && aums.length > 0) {
    aum = isBuying ? aums[0] : aums[1];
  }

  // NEW METHOD FOR BLP PRICE CAL
  // let aum;
  // if (aums && aums.length > 0) {
  //   aum = aums[0].add(aums[1]).div(2);
  // }

  const blpPrice =
    aum && aum.gt(0) && blpSupply.gt(0)
      ? aum.mul(expandDecimals(1, BLP_DECIMALS)).div(blpSupply)
      : expandDecimals(1, USD_DECIMALS);
  let blpBalanceUsd;
  if (blpBalance) {
    blpBalanceUsd = blpBalance.mul(blpPrice).div(expandDecimals(1, BLP_DECIMALS));
  }
  const blpSupplyUsd = blpSupply.mul(blpPrice).div(expandDecimals(1, BLP_DECIMALS));

  let reserveAmountUsd;
  if (reservedAmount) {
    reserveAmountUsd = reservedAmount.mul(blpPrice).div(expandDecimals(1, BLP_DECIMALS));
  }

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const swapToken = getToken(chainId, swapTokenAddress);
  const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);

  const swapTokenBalance = swapTokenInfo && swapTokenInfo.balance ? swapTokenInfo.balance : bigNumberify(0);

  const swapAmount = parseValue(swapValue, swapToken && swapToken.decimals);
  const blpAmount = parseValue(blpValue, BLP_DECIMALS);

  const needApproval =
    isBuying && swapTokenAddress !== AddressZero && tokenAllowance && swapAmount && swapAmount.gt(tokenAllowance);

  const needApproval2 =
    tokenAllowanceBlpToken && swapAmount && swapAmount.gt(tokenAllowanceBlpToken);

  const swapUsdMin = getUsd(swapAmount, swapTokenAddress, false, infoTokens);
  const blpUsdMax = blpAmount && blpPrice ? blpAmount.mul(blpPrice).div(expandDecimals(1, BLP_DECIMALS)) : undefined;

  let isSwapTokenCapReached;
  if (swapTokenInfo.managedUsd && swapTokenInfo.maxUsdbAmount) {
    isSwapTokenCapReached = swapTokenInfo.managedUsd.gt(
      adjustForDecimals(swapTokenInfo.maxUsdbAmount, USDB_DECIMALS, USD_DECIMALS)
    );
  }

  const onSwapValueChange = (e) => {
    setAnchorOnSwapAmount(true);
    setSwapValue(e.target.value);
  };

  const onBlpValueChange = (e) => {
    setAnchorOnSwapAmount(false);
    setBlpValue(e.target.value);
  };

  const onSelectSwapToken = (token) => {
    setSwapTokenAddress(token.address);
    setIsWaitingForApproval(false);
    setIsWaitingForApproval2(false);
  };

  const nativeToken = getTokenInfo(infoTokens, AddressZero);

  let totalApr = bigNumberify(0);

  let feeBlpTrackerAnnualRewardsUsd;
  let feeBlpTrackerApr;
  if (
    stakingData &&
    stakingData.feeBlpTracker &&
    stakingData.feeBlpTracker.tokensPerInterval &&
    nativeToken &&
    nativeToken.minPrice &&
    blpSupplyUsd &&
    blpSupplyUsd.gt(0)
  ) {
    feeBlpTrackerAnnualRewardsUsd = stakingData.feeBlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(nativeToken.minPrice)
      .div(expandDecimals(1, 18));
    feeBlpTrackerApr = feeBlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(blpSupplyUsd);
    totalApr = totalApr.add(feeBlpTrackerApr);
  }

  let stakedBlpTrackerAnnualRewardsUsd;
  let stakedBlpTrackerApr;

  if (
    basePrice &&
    stakingData &&
    stakingData.stakedBlpTracker &&
    stakingData.stakedBlpTracker.tokensPerInterval &&
    blpSupplyUsd &&
    blpSupplyUsd.gt(0)
  ) {
    stakedBlpTrackerAnnualRewardsUsd = stakingData.stakedBlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(basePrice)
      .div(expandDecimals(1, 18));
    stakedBlpTrackerApr = stakedBlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(blpSupplyUsd);
    totalApr = totalApr.add(stakedBlpTrackerApr);
  }

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnSwapAmount) {
        if (!swapAmount) {
          setBlpValue("");
          setFeeBasisPoints("");
          return;
        }

        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyBlpToAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            blpPrice,
            usdbSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, BLP_DECIMALS, BLP_DECIMALS);
          setBlpValue(nextValue);
          setFeeBasisPoints(feeBps);
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellBlpFromAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            blpPrice,
            usdbSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, BLP_DECIMALS, BLP_DECIMALS);
          setBlpValue(nextValue);
          setFeeBasisPoints(feeBps);
        }

        return;
      }

      if (!blpAmount) {
        setSwapValue("");
        setFeeBasisPoints("");
        return;
      }

      if (swapToken) {
        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyBlpFromAmount(
            blpAmount,
            swapTokenAddress,
            infoTokens,
            blpPrice,
            usdbSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals);
          setSwapValue(nextValue);
          setFeeBasisPoints(feeBps);
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellBlpToAmount(
            blpAmount,
            swapTokenAddress,
            infoTokens,
            blpPrice,
            usdbSupply,
            totalTokenWeights,
            true
          );

          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals);
          setSwapValue(nextValue);
          setFeeBasisPoints(feeBps);
        }
      }
    };

    updateSwapAmounts();
  }, [
    isBuying,
    anchorOnSwapAmount,
    swapAmount,
    blpAmount,
    swapToken,
    swapTokenAddress,
    infoTokens,
    blpPrice,
    usdbSupply,
    totalTokenWeights,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const switchSwapOption = (hash = "") => {
    history.push(`${history.location.pathname}#${hash}`);
    props.setIsBuying(hash === "redeem" ? false : true);
  };

  const fillMaxAmount = () => {
    if (isBuying) {
      setAnchorOnSwapAmount(true);
      setSwapValue(formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals));
      return;
    }

    setAnchorOnSwapAmount(false);
    setBlpValue(formatAmountFree(maxSellAmount, BLP_DECIMALS, BLP_DECIMALS));
  };

  const getError = () => {
    if (!isBuying && inCooldownWindow) {
      return [`Redemption time not yet reached`];
    }

    if (!swapAmount || swapAmount.eq(0)) {
      return ["Enter An Amount"];
    }
    if (!blpAmount || blpAmount.eq(0)) {
      return ["Enter An Amount"];
    }

    if (isBuying) {
      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (swapTokenInfo && swapTokenInfo.balance && swapAmount && swapAmount.gt(swapTokenInfo.balance)) {
        return [`INSUFFICIENT ${swapTokenInfo.symbol} BALANCE`];
      }

      if (swapTokenInfo.maxUsdbAmount && swapTokenInfo.usdbAmount && swapUsdMin) {
        const usdbFromAmount = adjustForDecimals(swapUsdMin, USD_DECIMALS, USDB_DECIMALS);
        const nextUsdbAmount = swapTokenInfo.usdbAmount.add(usdbFromAmount);
        if (swapTokenInfo.maxUsdbAmount.gt(0) && nextUsdbAmount.gt(swapTokenInfo.maxUsdbAmount)) {
          return [`${swapTokenInfo.symbol} pool exceeded, try different token`, true];
        }
      }
    }

    if (!isBuying) {
      if (maxSellAmount && blpAmount && blpAmount.gt(maxSellAmount)) {
        return [`INSUFFICIENT BLP BALANCE`];
      }

      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        swapTokenInfo &&
        swapTokenInfo.availableAmount &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.availableAmount)
      ) {
        return [`INSUFFICIENT LIQUIDITY`];
      }
    }

    return [false];
  };

  const isPrimaryEnabled = () => {
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if(error){
      console.error(error);
    }
    if (error && !modal) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if ((needApproval2 && isWaitingForApproval2) || isApproving2) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isApproving2) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (isSwapTokenCapReached) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return "Connect Wallet";
    }
    const [error, modal] = getError();
    if(error){
      console.error(error);
    }

    if (error && !modal) {
      return error;
    }
    if (isBuying && isSwapTokenCapReached) {
      return `Max Capacity for ${swapToken.symbol} Reached`;
    }

    if (needApproval && isWaitingForApproval) {
      return "Waiting for Approval";
    }
    if (isApproving) {
      return `Approving ${swapToken.symbol}...`;
    }
    if (needApproval) {
      return `Approve ${swapToken.symbol}`;
    }

    if (needApproval2 && isWaitingForApproval2) {
      return "Waiting for Approval";
    }
    if (isApproving2) {
      return `Approving BLP Token...`;
    }
    if (needApproval2) {
      return `Approve BLP Token`;
    }

    if (isSubmitting) {
      return isBuying ? `Buying...` : `Selling...`;
    }

    return isBuying ? "Mint" : "Redeem";
  };

  const approveFromToken = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: swapToken.address,
      spender: blpManagerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
    });
  };

  const approveFromToken2 = () => {
    approveTokens({
      setIsApproving: setIsApproving2,
      library,
      tokenAddress: "0x2168eb98C6D416Afb85E7beef5abDc4FB4177dfE",
      spender: "0x265a30f14E34248567B5B0a83978C38dF38D0C60",
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval2(true);
      },
      infoTokens,
      getTokenInfo,
    });
  };

  const buyBlp = () => {
    setIsSubmitting(true);

    const minBlp = blpAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter, library.getSigner());
    const method = swapTokenAddress === AddressZero ? "mintAndStakeBlpETH" : "mintAndStakeBlp";
    const params = swapTokenAddress === AddressZero ? [0, minBlp] : [swapTokenAddress, swapAmount, 0, minBlp];
    const value = swapTokenAddress === AddressZero ? swapAmount : 0;

    callContract(chainId, contract, method, params, {
      value,
      sentMsg: "Buy submitted.",
      failMsg: "Buy failed.",
      successMsg: `${formatAmount(blpAmount, 18, 4, true)} BLP bought with ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true
      )} ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const sellBlp = () => {
    setIsSubmitting(true);

    const minOut = swapAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter, library.getSigner());
    const method = swapTokenAddress === AddressZero ? "unstakeAndRedeemBlpETH" : "unstakeAndRedeemBlp";
    const params =
      swapTokenAddress === AddressZero ? [blpAmount, minOut, account] : [swapTokenAddress, blpAmount, minOut, account];

    callContract(chainId, contract, method, params, {
      sentMsg: "Sell submitted!",
      failMsg: "Sell failed.",
      successMsg: `${formatAmount(blpAmount, 18, 4, true)} BLP sold for ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true
      )} ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onClickPrimary = () => {
    if (!active) {
      connectWallet();
      return;
    }

    if (needApproval2) {
      approveFromToken2();
      return;
    }
    if (needApproval) {
      approveFromToken();
      return;
    }

    const [error, modal] = getError();
    if(error){
      console.error(error);
    }

    if (modal) {
      return;
    }

    if (isBuying) {
      buyBlp();
    } else {
      sellBlp();
    }
  };

  let payLabel = "Pay";
  let receiveLabel = "Receive";
  let payBalance = "$0.00";
  let receiveBalance = "$0.00";
  if (isBuying) {
    if (swapUsdMin) {
      payBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
    if (blpUsdMax) {
      receiveBalance = `$${formatAmount(blpUsdMax, USD_DECIMALS, 2, true)}`;
    }
  } else {
    if (blpUsdMax) {
      payBalance = `$${formatAmount(blpUsdMax, USD_DECIMALS, 2, true)}`;
    }
    if (swapUsdMin) {
      receiveBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
  }

  const selectToken = (token) => {
    setAnchorOnSwapAmount(false);
    setSwapTokenAddress(token.address);
    helperToast.success(`${token.symbol} selected in order form`);
  };

  let feePercentageText = formatAmount(feeBasisPoints, 2, 2, true, "-");
  if (feeBasisPoints !== undefined && feeBasisPoints.toString().length > 0) {
    feePercentageText += "%";
  }

  let maxSellAmount = blpBalance;
  if (blpBalance && reservedAmount) {
    maxSellAmount = blpBalance.sub(reservedAmount);
  }

  const wrappedTokenSymbol = getWrappedToken(chainId).symbol;
  const nativeTokenSymbol = getNativeToken(chainId).symbol;

  const onSwapOptionChange = (opt) => {
    if (opt === "Redeem") {
      switchSwapOption("redeem");
    } else {
      switchSwapOption();
    }
  };

  return (
    <div className="BlpSwap">
      {/* <div className="Page-title-section">
        <div className="Page-title">{isBuying ? "Buy BLP" : "Sell BLP"}</div>
        {isBuying && <div className="Page-description">
          Purchase <a href=" https://docs.swapbased.finance/tokenomics/blp" target="_blank" rel="noopener noreferrer">BLP tokens</a> to earn {nativeTokenSymbol} fees from swaps and leverage trading.<br/>
          Note that there is a minimum holding time of 15 minutes after a purchase.<br/>
          <div>View <Link to="/earn">staking</Link> page.</div>
        </div>}
        {!isBuying && <div className="Page-description">
          Redeem your BLP tokens for any supported asset.
          {inCooldownWindow && <div>
            BLP tokens can only be redeemed 15 minutes after your most recent purchase.<br/>
            Your last purchase was at {formatDateTime(lastPurchaseTime)}, you can redeem BLP tokens after {formatDateTime(redemptionTime)}.<br/>
          </div>}
          <div>View <Link to="/earn">staking</Link> page.</div>
        </div>}
      </div> */}
      <div className="BlpSwap-content">
        <div className="App-card BlpSwap-stats-card">
          <div className="App-card-title">
            <div className="App-card-title-mark">
              <div className="App-card-title-mark-icon">
                <img src={blp40Icon} alt="blp40Icon" style={{width: '40px', height: '40px'}}/>
                  <img src={base16Icon} style={{width: '16px', height: '16px'}} alt="base16Icon" className="selected-network-symbol" />
              </div>
              <div className="App-card-title-mark-info">
                <div className="App-card-title-mark-title">BLP</div>
                <div className="App-card-title-mark-subtitle">BLP</div>
              </div>
            </div>
          </div>
          <div className="App-card-divider"></div>
          <div className="App-card-content">
            <div className="App-card-row">
              <div className="label">Price</div>
              <div className="value">${formatAmount(blpPrice, USD_DECIMALS, BASEBLP_DISPLAY_DECIMALS, true)}</div>
            </div>
            <div className="App-card-row">
              <div className="label">Wallet</div>
              <div className="value">
                {formatAmount(blpBalance, BLP_DECIMALS, 4, true)} BLP ($
                {formatAmount(blpBalanceUsd, USD_DECIMALS, 2, true)})
              </div>
            </div>
          </div>
          <div className="App-card-divider"></div>
          <div className="App-card-content">
            {!isBuying && (
              <div className="App-card-row">
                <div className="label">Reserved</div>
                <div className="value">
                  <Tooltip
                    handle={`${formatAmount(reservedAmount, 18, 4, true)} BLP ($${formatAmount(
                      reserveAmountUsd,
                      USD_DECIMALS,
                      2,
                      true
                    )})`}
                    position="right-bottom"
                    renderContent={() =>
                      `${formatAmount(reservedAmount, 18, 4, true)} Reserved BLP for vesting.`
                    }
                  />
                </div>
              </div>
            )}
            {/* <div className="App-card-row">
              <div className="label">APR</div>
              <div className="value">
                <Tooltip
                  handle={`${formatAmount(totalApr, 2, 2, true)}%`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <>
                        <div className="Tooltip-row">
                          <span className="label">
                            {nativeTokenSymbol} ({wrappedTokenSymbol}) APR
                          </span>
                          <span>{formatAmount(feeBlpTrackerApr, 2, 2, false)}%</span>
                        </div>
                        <div className="Tooltip-row">
                          <span className="label">Escrowed BASE APR</span>
                          <span>{formatAmount(stakedBlpTrackerApr, 2, 2, false)}%</span>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div> */}
            <div className="App-card-row">
              <div className="label">Total Supply</div>
              <div className="value">
                {formatAmount(blpSupply, BLP_DECIMALS, 4, true)} BLP ($
                {formatAmount(blpSupplyUsd, USD_DECIMALS, 2, true)})
              </div>
            </div>
          </div>
        </div>
        <div className="BlpSwap-box App-box">
          <Tab
            options={["Mint", "Redeem"]}
            option={tabLabel}
            onChange={onSwapOptionChange}
            className="Exchange-swap-option-tabs"
          />
          {isBuying && (
            <BuyInputSection
              topLeftLabel={payLabel}
              topRightLabel={`Balance: `}
              tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
              inputValue={swapValue}
              onInputValueChange={onSwapValueChange}
              showMaxButton={swapValue !== formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals)}
              onClickTopRightLabel={fillMaxAmount}
              onClickMax={fillMaxAmount}
              selectedToken={swapToken}
              balance={payBalance}
            >
              <TokenSelector
                label="Pay"
                chainId={chainId}
                tokenAddress={swapTokenAddress}
                onSelectToken={onSelectSwapToken}
                tokens={whitelistedTokens}
                infoTokens={infoTokens}
                className="BlpSwap-from-token"
                showSymbolImage={true}
                showTokenImgInDropdown={true}
              />
            </BuyInputSection>
          )}

          {!isBuying && (
            <BuyInputSection
              topLeftLabel={payLabel}
              topRightLabel={`Available: `}
              tokenBalance={`${formatAmount(maxSellAmount, BLP_DECIMALS, 4, true)}`}
              inputValue={blpValue}
              onInputValueChange={onBlpValueChange}
              showMaxButton={blpValue !== formatAmountFree(maxSellAmount, BLP_DECIMALS, BLP_DECIMALS)}
              onClickTopRightLabel={fillMaxAmount}
              onClickMax={fillMaxAmount}
              balance={payBalance}
              defaultTokenName={"BLP"}
            >
              <div className="selected-token">
                BLP <img src={blp24Icon} style={{width: '24px', height: '24px'}} alt="blp24Icon" />
              </div>
            </BuyInputSection>
          )}

          <div className="AppOrder-ball-container">
            <div className="AppOrder-ball">
              <img
                src={arrowIcon}
                alt="arrowIcon"
                onClick={() => {
                  setIsBuying(!isBuying);
                  switchSwapOption(isBuying ? "redeem" : "");
                }}
              />
            </div>
          </div>

          {isBuying && (
            <BuyInputSection
              topLeftLabel={receiveLabel}
              topRightLabel={`Balance: `}
              tokenBalance={`${formatAmount(blpBalance, BLP_DECIMALS, 4, true)}`}
              inputValue={blpValue}
              onInputValueChange={onBlpValueChange}
              balance={receiveBalance}
              defaultTokenName={"BLP"}
            >
              <div className="selected-token">
                BLP <img src={blp24Icon} style={{width: '24px', height: '24px'}} alt="blp24Icon" />
              </div>
            </BuyInputSection>
          )}

          {!isBuying && (
            <BuyInputSection
              topLeftLabel={receiveLabel}
              topRightLabel={`Balance: `}
              tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
              inputValue={swapValue}
              onInputValueChange={onSwapValueChange}
              balance={receiveBalance}
              selectedToken={swapToken}
            >
              <TokenSelector
                label="Receive"
                chainId={chainId}
                tokenAddress={swapTokenAddress}
                onSelectToken={onSelectSwapToken}
                tokens={whitelistedTokens}
                infoTokens={infoTokens}
                className="BlpSwap-from-token"
                showSymbolImage={true}
                showTokenImgInDropdown={true}
              />
            </BuyInputSection>
          )}
          <div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">{feeBasisPoints > 50 ? "WARNING: High Fees" : "Fees"}</div>
              <div className="align-right fee-block">
                {isBuying && (
                  <Tooltip
                    handle={isBuying && isSwapTokenCapReached ? "NA" : feePercentageText}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          {feeBasisPoints > 50 && <div>Select an alternative asset for providing liquidity to reduce fees.</div>}
                          To get the lowest fee percentages, look in the "SAVE FEES" section below.
                        </>
                      );
                    }}
                  />
                )}
                {!isBuying && (
                  <Tooltip
                    handle={feePercentageText}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          {feeBasisPoints > 50 && <div>To reduce fees, select a different asset to remove liquidity.</div>}
                          To get the lowest fee percentages, look in the "SAVE FEES" section below.
                        </>
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="BlpSwap-cta Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button text-uppercase" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </div>
      </div>
      <div className="Tab-title-section" style={{marginLeft:-12}}>
        <div className="Page-title">SAVE FEES</div>
        {isBuying && (
          <div className="Page-description">
           The fees can vary depending on the asset you want to use to add liquidity to BLP.
            <br /> Please enter the desired amount of BLP or asset to be added to the interface, and you can compare the fees here.
          </div>
        )}
        {!isBuying && (
          <div className="Page-description">
                     The fees can vary depending on the asset you want to use to add liquidity to BLP.
            <br /> Please enter the desired amount of BLP or asset to be added to the interface, and you can compare the fees here.
          </div>
        )}
      </div>
      <div className="BlpSwap-token-list">
        {/* <div className="BlpSwap-token-list-content"> */}
        <table className="token-table">
          <thead>
            <tr>
              <th>TOKEN</th>
              <th>PRICE</th>
              <th>
                {isBuying ? (
                  <Tooltip
                    handle={"AVAILABLE"}
                    tooltipIconPosition="right"
                    position="right-bottom text-none"
                    renderContent={() => "Available amount to deposit into BLP."}
                  />
                ) : (
                  <Tooltip
                    handle={"AVAILABLE"}
                    tooltipIconPosition="right"
                    position="right-bottom text-none"
                    renderContent={() => {
                      return (
                        <>
                          <div>Available amount to -LIQ. from BLP.</div>
                          <div>Funds that are not being utilized by current open positions.</div>
                        </>
                      );
                    }}
                  />
                )}
              </th>
              <th>WALLET</th>
              <th>
                <Tooltip
                  handle={"FEES"}
                  tooltipIconPosition="right"
                  position="right-bottom text-none"
                  renderContent={() => {
                    return (
                      <>
                        <div>Fees will be shown once you have entered an amount in the order form.</div>
                      </>
                    );
                  }}
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tokenList.map((token) => {
              let tokenFeeBps;
              if (isBuying) {
                const { feeBasisPoints: feeBps } = getBuyBlpFromAmount(
                  blpAmount,
                  token.address,
                  infoTokens,
                  blpPrice,
                  usdbSupply,
                  totalTokenWeights
                );
                tokenFeeBps = feeBps;
              } else {
                const { feeBasisPoints: feeBps } = getSellBlpToAmount(
                  blpAmount,
                  token.address,
                  infoTokens,
                  blpPrice,
                  usdbSupply,
                  totalTokenWeights
                );
                tokenFeeBps = feeBps;
              }
              const tokenInfo = getTokenInfo(infoTokens, token.address);
              let managedUsd;
              if (tokenInfo && tokenInfo.managedUsd) {
                managedUsd = tokenInfo.managedUsd;
              }
              let availableAmountUsd;
              if (tokenInfo && tokenInfo.minPrice && tokenInfo.availableAmount) {
                availableAmountUsd = tokenInfo.availableAmount
                  .mul(tokenInfo.minPrice)
                  .div(expandDecimals(1, token.decimals));
              }
              let balanceUsd;
              if (tokenInfo && tokenInfo.minPrice && tokenInfo.balance) {
                balanceUsd = tokenInfo.balance.mul(tokenInfo.minPrice).div(expandDecimals(1, token.decimals));
              }

              var tokenImage = null;

              try {
                if (token.symbol.toLowerCase() === "eth" || token.symbol.toLowerCase() === "weth") {
                  tokenImage = require("../../img/logo-eth.png");
                }
                else {
                  tokenImage = require("../../img/" + token.symbol + ".png");
                }
              } catch (error) {
                console.error(error);
              }
              let isCapReached = tokenInfo.managedAmount?.gt(tokenInfo.maxUsdbAmount);

              let amountLeftToDeposit;
              if (tokenInfo.maxUsdbAmount && tokenInfo.maxUsdbAmount.gt(0)) {
                amountLeftToDeposit = adjustForDecimals(tokenInfo.maxUsdbAmount, USDB_DECIMALS, USD_DECIMALS).sub(
                  tokenInfo.managedUsd
                );
              }
              function renderFees() {
                const swapUrl = `https://swapbased.finance/#/swap`;
                // const swapUrl = `https://swapbased.finance/#/swap?inputCurrency=${token.address}`;
                switch (true) {
                  case (isBuying && isCapReached) || (!isBuying && managedUsd?.lt(1)):
                    return (
                      <Tooltip
                        handle="NA"
                        position="right-bottom"
                        renderContent={() => (
                          <div>
                            Max pool capacity reached for {tokenInfo.symbol}
                            <br />
                            <br />
                            Please mint BLP using another token
                            <br />
                            <p>
                              <a href={swapUrl} target="_blank" rel="noreferrer">
                                Swap on {chainId === BASE ? "SwapBased" : "SwapBased"}
                              </a>
                            </p>
                          </div>
                        )}
                      />
                    );
                  case (isBuying && !isCapReached) || (!isBuying && managedUsd?.gt(0)):
                    return `${formatAmount(tokenFeeBps, 2, 2, true, "-")}${
                      tokenFeeBps !== undefined && tokenFeeBps.toString().length > 0 ? "%" : ""
                    }`;
                  default:
                    return "";
                }
              }

              return (
                <tr key={token.symbol}>
                  <td>
                    <div className="App-card-title-info">
                      <div className="App-card-title-info-icon">
                        <img src={tokenImage && tokenImage.default} alt={token.symbol} width="40px" />
                      </div>
                      <div className="App-card-title-info-text">
                        <div className="App-card-info-title">{token.name}</div>
                        <div className="App-card-info-subtitle">{token.symbol}</div>
                      </div>
                      <div>
                        <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                      </div>
                    </div>
                  </td>
                  <td>${formatKeyAmount(tokenInfo, "maxPrimaryPrice", USD_DECIMALS, tokenInfo.displayDecimals, true)}
                  </td>
                  <td>
                    {isBuying && (
                      <div>
                        <Tooltip
                          handle={
                            amountLeftToDeposit && amountLeftToDeposit.lt(0)
                              ? "$0.00"
                              : `$${formatAmount(amountLeftToDeposit, USD_DECIMALS, 2, true)}`
                          }
                          position="right-bottom"
                          tooltipIconPosition="right"
                          renderContent={() => {
                            return (
                              <>
                                Current Pool Amount: ${formatAmount(managedUsd, USD_DECIMALS, 2, true)} (
                                {formatKeyAmount(tokenInfo, "poolAmount", token.decimals, 2, true)} {token.symbol})
                                <br />
                                <br />
                                Max Pool Capacity: ${formatAmount(tokenInfo.maxUsdbAmount, 18, 0, true)}
                              </>
                            );
                          }}
                        />
                      </div>
                    )}
                    {!isBuying && (
                      <div>
                        {formatKeyAmount(tokenInfo, "availableAmount", token.decimals, 2, true)} {token.symbol} ($
                        {formatAmount(availableAmountUsd, USD_DECIMALS, 2, true)})
                      </div>
                    )}
                  </td>
                  <td>
                    {formatKeyAmount(tokenInfo, "balance", tokenInfo.decimals, 2, true)} {tokenInfo.symbol} ($
                    {formatAmount(balanceUsd, USD_DECIMALS, 2, true)})
                  </td>
                  <td>{renderFees()}</td>
                  <td>
                    <button
                      className={cx("App-button-option action-btn", isBuying ? "buying" : "selling")}
                      onClick={() => selectToken(token)}
                    >
                      {isBuying ? "Mint with " + token.symbol : "Withdraw as " + token.symbol}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="token-grid">
          {tokenList.map((token) => {
            let tokenFeeBps;
            if (isBuying) {
              const { feeBasisPoints: feeBps } = getBuyBlpFromAmount(
                blpAmount,
                token.address,
                infoTokens,
                blpPrice,
                usdbSupply,
                totalTokenWeights
              );
              tokenFeeBps = feeBps;
            } else {
              const { feeBasisPoints: feeBps } = getSellBlpToAmount(
                blpAmount,
                token.address,
                infoTokens,
                blpPrice,
                usdbSupply,
                totalTokenWeights
              );
              tokenFeeBps = feeBps;
            }
            const tokenInfo = getTokenInfo(infoTokens, token.address);
            let managedUsd;
            if (tokenInfo && tokenInfo.managedUsd) {
              managedUsd = tokenInfo.managedUsd;
            }
            let availableAmountUsd;
            if (tokenInfo && tokenInfo.minPrice && tokenInfo.availableAmount) {
              availableAmountUsd = tokenInfo.availableAmount
                .mul(tokenInfo.minPrice)
                .div(expandDecimals(1, token.decimals));
            }
            let balanceUsd;
            if (tokenInfo && tokenInfo.minPrice && tokenInfo.balance) {
              balanceUsd = tokenInfo.balance.mul(tokenInfo.minPrice).div(expandDecimals(1, token.decimals));
            }

            let amountLeftToDeposit;
            if (tokenInfo.maxUsdbAmount && tokenInfo.maxUsdbAmount.gt(0)) {
              amountLeftToDeposit = adjustForDecimals(tokenInfo.maxUsdbAmount, USDB_DECIMALS, USD_DECIMALS).sub(
                tokenInfo.managedUsd
              );
            }
            let isCapReached = tokenInfo.managedAmount?.gt(tokenInfo.maxUsdbAmount);

            var tokenImage = null;

            try {
            	if (token.symbol.toLowerCase() === "eth" || token.symbol.toLowerCase() === "weth") {
            	  tokenImage = require("../../img/logo-eth.png");
            	}
            	else {
            	  tokenImage = require("../../img/" + token.symbol + ".png");
            	}
            } catch (error) {
              console.error(error);
            }

            function renderFees() {
              switch (true) {
                case (isBuying && isCapReached) || (!isBuying && managedUsd?.lt(1)):
                  return (
                    <Tooltip
                      handle="NA"
                      position="right-bottom"
                      renderContent={() =>
                        `Maximum pool capacity reached for ${tokenInfo.symbol}. Please add +LIQ. with another token for the BLP`
                      }
                    />
                  );
                case (isBuying && !isCapReached) || (!isBuying && managedUsd?.gt(0)):
                  return `${formatAmount(tokenFeeBps, 2, 2, true, "-")}${
                    tokenFeeBps !== undefined && tokenFeeBps.toString().length > 0 ? "%" : ""
                  }`;
                default:
                  return "";
              }
            }

            return (
              <div className="App-card" key={token.symbol}>
                  <img src={tokenImage && tokenImage.default} alt={token.symbol} width="40px" /><span className="mt-2 mx-1">{token.name}</span>
                <div className="App-card-title">{/* {token.name} */}</div>
                <div className="App-card-divider"></div>
                <div className="App-card-content">
                  <div className="App-card-row">
                    <div className="label">Price</div>
                    <div>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, tokenInfo.displayDecimals, true)}</div>
                  </div>
                  {isBuying && (
                    <div className="App-card-row">
                      <Tooltip
                        className="label"
                        handle="Available"
                        position="left-bottom"
                        renderContent={() => "Available amount to deposit into BLP."}
                      />
                      <div>
                        <Tooltip
                          handle={amountLeftToDeposit && `$${formatAmount(amountLeftToDeposit, USD_DECIMALS, 2, true)}`}
                          position="right-bottom"
                          tooltipIconPosition="right"
                          renderContent={() => {
                            return (
                              <>
                                Current Pool Amount: ${formatAmount(managedUsd, USD_DECIMALS, 2, true)} (
                                {formatKeyAmount(tokenInfo, "poolAmount", token.decimals, 2, true)} {token.symbol})
                                <br />
                                <br />
                                Maximum Pool Capacity: ${formatAmount(tokenInfo.maxUsdbAmount, 18, 0, true)}
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {!isBuying && (
                    <div className="App-card-row">
                      <Tooltip
                        handle="Available"
                        position="left-bottom"
                        renderContent={() => {
                          return (
                            <>
                              <div>Available amount to withdraw from BLP.</div>
                              <div>Funds not utilized by current open positions.</div>
                            </>
                          );
                        }}
                      />
                      <div>
                        {formatKeyAmount(tokenInfo, "availableAmount", token.decimals, 2, true)} {token.symbol} ($
                        {formatAmount(availableAmountUsd, USD_DECIMALS, 2, true)})
                      </div>
                    </div>
                  )}

                  <div className="App-card-row">
                    <div className="label">Wallet</div>
                    <div>
                      {formatKeyAmount(tokenInfo, "balance", tokenInfo.decimals, 2, true)} {tokenInfo.symbol} ($
                      {formatAmount(balanceUsd, USD_DECIMALS, 2, true)})
                    </div>
                  </div>
                  <div className="App-card-row">
                    <div className="label">
                      {tokenFeeBps ? (
                        "Fees"
                      ) : (
                        <Tooltip
                          handle={`Fees`}
                          renderContent={() => `Please enter an amount to see fee percentages`}
                        />
                      )}
                    </div>
                    <div>{renderFees()}</div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-options">
                    {isBuying && (
                      <button className="App-button-option App-card-option text-uppercase" onClick={() => selectToken(token)}>
                        +Liq. with {token.symbol}
                      </button>
                    )}
                    {!isBuying && (
                      <button className="App-button-option App-card-option text-uppercase" onClick={() => selectToken(token)}>
                        -liq. for {token.symbol}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
