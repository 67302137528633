export const CONTRACTS = {

/*
  8453: {
    // base
    Vault: "0xDd37b2eB92F97dd09cEd1f1d20A73aA340b2311A",
    Router: "0xEdCD6442143188Deb586e182B7900dFb8707Bc27",
    VaultReader: "0xF6aFcd935c28365CCe278Fed78b26Fe2BC9ae518",
    Reader: "0xcCf27643fa6C4FC844a8945b7c2F8bd562153649",
    BlpManager: "0xB0015714B541A99265f529c7c0d34DA47deCA5b2",
    RewardRouter: "0xf42Fe9b8e2009be073f1AeDc57a72623AC4F3045",
    RewardReader: "0xf5288515FD1394B814e38293f41dC22ad8085532",
    BLP: "0xe6dA6E31540239b4a09eC574f147004D50f1b140",
    BASE: "0xd07379a755A8f11B57610154861D694b2A0f615a", // DONE BASE
    ES_BASE: "0xc22Ffa318051d8aF4E5f2E2732d7049486fcE093",
    BN_BASE: "0x71e7EC880873af0FE33Ad988F862bE200FdD85cC",
    USDB: "0x393A6a6850e0788e496d73c50a436606e6CcA874",
    ES_BASE_IOU: "0x0000000000000000000000000000000000000000",
    StakedBaseTracker: "0x1886Fa412064137BDe9Ea996EA2bB85377de8aB6",
    BonusBaseTracker: "0xACF05e2a9b757e70C2900432c9b4E08Fdc54dD53",
    FeeBaseTracker: "0x85cd1F9550CdF662944bEc3E58021f8dbE6fE878",
    StakedBlpTracker: "0xFd6e1d4eaDbc80322F62e7148AD223596A005238",
    FeeBlpTracker: "0x77D32f6f5F454Fa76f93859f49f9431CeA8c225F",
    StakedBaseDistributor: "0x6AB47d47cF45C4aaA5c7F33c6632390674EfA294",
    StakedBlpDistributor: "0x8E7a8d3CAeEbbe9A92faC4db19424218aE6791a3",
    BaseVester: "0x8c634b72fF5d6A9F6a0281EEF36365E4db8bDF8d",
    BlpVester: "0x959306A913D041D4f634310f6aD3789cBF0e9b18",
    OrderBook: "0xa6f8431C9eEe4Ac2859207aF4004F7a948924c30",
    OrderExecutor: "0x8b9e409a817c7dC1a42C3DDe9E85dcBFF602c0fC",
    OrderBookReader: "0x8b9e409a817c7dC1a42C3DDe9E85dcBFF602c0fC",
    PositionRouter: "0xd99748782d7643b00C36a4Bb296C4A099dF98Ff3",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
    TraderJoeBaseAvaxPool: "0x0000000000000000000000000000000000000000",

    UniswapBaseUsdcPool: "0x76fa7935a5AFEf7fefF1C88bA858808133058908",
    NATIVE_TOKEN: "0x4200000000000000000000000000000000000006",
  },
  421611: {
    // arbitrum testnet
    Vault: "0xA4704fBfaf7c89511668052931Ba0f1816D2c9d3",
    Router: "0x526f42EA12E167E36E3De747187f53b6989d121A",
    Reader: "0x24e9C35B953F2aFb56f92592d2E8Ac2E58eA6912",
    NATIVE_TOKEN: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
    OrderBook: "0x84B1FEA4A2c1e0C07f34755ac4cf5aD26a07485d",
    OrderBookReader: "0x9Ed8Aea78D6Af0D4089f4D24066b8EFdA59747D9",

    // everything else is from 97
    AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
    AmmFactoryV2: "0x1111111111111111111111111111111111111111",
    GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDB: "0x2D549bdBf810523fe9cd660cC35fE05f0FcAa028",
    GMT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
    XGMT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
    GMT_USDB_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
    XGMT_USDB_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
    GMT_USDB_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
    XGMT_USDB_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
    USDB_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
    XGMT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
    GMT_USDB_FARM_TRACKER_XGMT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
    GMT_USDB_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
    XGMT_USDB_FARM_TRACKER_XGMT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
    XGMT_USDB_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
    GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDB_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDB_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  },
  42161: {
    // arbitrum mainnet
    Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
    Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
    VaultReader: "0x2A735dd7725a4AC00D2F334F88764DDBF2EE6694",
    Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
    BlpManager: "0x321F653eED006AD1C29D174e17d96351BDe22649",
    RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
    RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    BLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    BASE: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    ES_BASE: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
    BN_BASE: "0x35247165119B69A40edD5304969560D0ef486921",
    USDB: "0x45096e7aA921f27590f8F19e457794EB09678141",
    ES_BASE_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
    StakedBaseTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    BonusBaseTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    FeeBaseTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    StakedBlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    FeeBlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

    StakedBaseDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    StakedBlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

    BaseVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
    BlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

    OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
    OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
    OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

    PositionRouter: "0x3D6bA331e3D9702C5e8A8d254e5d8a285F223aba",

    UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
    ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",
  },
  43114: {
    // avalanche
    Vault: "0x9ab2De34A33fB459b538c43f251eB825645e8595",
    Router: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8",
    VaultReader: "0x80785f96743d5Aef7725d88256fdBCfF43fBd112",
    Reader: "0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd",
    BlpManager: "0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F",
    RewardRouter: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327",
    RewardReader: "0x04Fc11Bd28763872d143637a7c768bD96E44c1b6",
    NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    BLP: "0x01234181085565ed162a948b6a5e88758CD7c7b8",
    BASE: "0x62edc0692BD897D2295872a9FFCac5425011c661",
    ES_BASE: "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
    BN_BASE: "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
    USDB: "0xc0253c3cC6aa5Ab407b5795a04c28fB063273894",
    ES_BASE_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

    StakedBaseTracker: "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
    BonusBaseTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    FeeBaseTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    StakedBlpTracker: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
    FeeBlpTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",

    StakedBaseDistributor: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    StakedBlpDistributor: "0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720",

    BaseVester: "0x472361d3cA5F49c8E633FB50385BfaD1e018b445",
    BlpVester: "0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A",

    OrderBook: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderExecutor: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderBookReader: "0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B",

    PositionRouter: "0x195256074192170d1530527abC9943759c7167d8",

    TraderJoeGmxAvaxPool: "0x0c91a070f862666bbcce281346be45766d874d98",
    ReferralStorage: "0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8",
    ReferralReader: "0x505Ce16D3017be7D76a7C2631C0590E71A975083",
  },

*/

  8453: {
    // base
    Vault: "0x210b49f74040A385840a3276E81bA9010954d064",
    Router: "0xd72d5Ad987BD875FD43Efee6258129064972Ed74",
    VaultReader: "0x02a73D670f98b6A7d48F32577F3B9A2d2A7BBCb8",
    Reader: "0xc7aDbCf46Da2F2b9Fd575aDaAEAb39A5669c497e",
    BlpManager: "0x1142c2dB68E9E87B7358e129A40771dBca0A34da",
    RewardRouter: "0x265a30f14E34248567B5B0a83978C38dF38D0C60",
    RewardReader: "0xE05986f84B4d74dD13f2743eA751c7E1E83076eb",
    BLP: "0x6ECCA85569F1101bb54Fcd71d81984084096d837",
    BASE: "0xd07379a755A8f11B57610154861D694b2A0f615a", // done
    ES_BASE: "0xdE30EF700a3B9Ce0C2b1150D6462613533a5f2A7",
    BN_BASE: "0x1081DF8cd3560894191ef26E0AC078d8d2f041C1",
    USDB: "0x025AaCB4A93626B49C1B43c0AfA4BD9e81938E9A",
    ES_BASE_IOU: "0x0000000000000000000000000000000000000000",
    StakedBaseTracker: "0x3bb51E85130c03455B408E77b0aff1FdC669D5aD",
    BonusBaseTracker: "0x1ef74F173948a0C000caa4bcE876a52FDFaE6949",
    FeeBaseTracker: "0xaE7093DDc18C1A8ab56CAD2c9d94eeC2DCe29b88",
    StakedBlpTracker: "0x73c7ce996503109A111CB71AFD00889390Fe7708",
    FeeBlpTracker: "0x772Defa308F27cCF8FDBEDa4860fE617846bc383",
    StakedBaseDistributor: "0x1376d6Ba696F2C201Cf5b44ee2B7751E55eC90b6",
    StakedBlpDistributor: "0x16CEDC246f2Dd4B01E28bAbD3D0e9Fc4724bC4c4",
    BaseVester: "0x7e9Fb800edee21561cCDC03051225C077095D29d",
    BlpVester: "0x1528935dC59D3B58644e8Ffb1ae0d0068D493f08",
    OrderBook: "0x1821e34C08888309546D156C378CEB848278D352",
    PositionManager: "0xa3d25D0037E50B6526678dA38c7211FcC519C1f0",
    OrderBookReader: "0xDFe54B029c787F6418F9088590E81981dc0EcD88",
    PositionRouter: "0x11b09dBB0b743E0963c80EE43746e85804aCb79e",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
    TraderJoeBaseAvaxPool: "0x0000000000000000000000000000000000000000",
    UniswapBaseUsdcPool: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
    NATIVE_TOKEN: "0x4200000000000000000000000000000000000006",
  },
};
