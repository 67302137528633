import React, { useEffect } from "react";
import Footer from "../../Footer";
import { NavLink } from "react-router-dom";
import "./BuyBASE.css";

import Synapse from "../../img/Synapse.svg";
import Multichain from "../../img/Multichain.png";
import Celler from "../../img/cbridgev2.svg";
import XPol from "../../img/xpollinate.svg";
import logo from "../../img/swapbased-logo.png";
import blpIcon from '../../img/logo-blp.svg'

export default function BuyBASE() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="BuyBASE">
      <div className="BuyBASE-container BuyBASE-container-pdsmallscreen default-container" style={{marginBottom:200}}>
        <div className="BuyBASE-title-section">
          <div className="BuyBASE-title">Buy BASE</div>
          <div className="BuyBASEBLP-description">
            <p>
              You can participate in the platform earnings by buying and staking
              BASE
            </p>
            To buy BASE, ensure you are connected to the Base Chain and
            have enough ETH for the gas fees.
            <br />
            <br />
            You can learn how to bridge ETH to Base Chain by following
            this:{" "}
            <a
              href="https://docs.swapbased.finance/faq#bridging"
              target="_blank"
              rel="noopener noreferrer"
              className="textcolorwhite"
            >
              tutorial
            </a>
            , Check the following alternative bridges for the best rates:
          </div>
          <div className="alternative-bridges">
            <a
              href="https://synapseprotocol.com/?inputCurrency=USDC&outputCurrency=USDC&outputChain=8453"
              target="_blank"
              rel="noopener noreferrer"
              className="Synapse"
            >
              <img src={Synapse} alt="Synapse" />
            </a>
            <a
              href="https://app.multichain.org/#/router"
              target="_blank"
              rel="noopener noreferrer"
              className="Multichain"
            >
              <img src={Multichain} alt="Multichain" />
            </a>
          </div>
          <p className="BuyBASEBLP-description">
            Learn more at{" "}
            <a
              href="https://docs.swapbased.finance/tokenomics"
              target="_blank"
              rel="noopener noreferrer" className="textcolorwhite"
            >
             docs.swapbased.finance/tokenomics
            </a>
          </p>
          <a
            href="https://swapbased.finance/#/swap?outputCurrency=0xd07379a755A8f11B57610154861D694b2A0f615a"
            target="_blank"
            rel="noopener noreferrer"
            className="BuyBASE-purchase-block"
          >
            <div className="BuyBASE-purchase-block-icon">
              <img src={logo} alt="logo" style={{width: '53px', height: '53px'}} />
            </div>
            <div className="BuyBASE-purchase-block-info">
              <div className="BuyBASE-purchase-block-info__title">Buy BASE</div>
              <div className="BuyBASE-purchase-block-info__subtitle">
                SwapBased Base Chain
              </div>
            </div>
          </a>
          <div className="BuyBASEBLP-description">If you wish to provide liquidity for BLP instead, you can find more info at <a href="https://docs.swapbased.finance/tokenomics/blp" className="textcolorwhite" target="_blank" rel="noopener noreferrer">docs.swapbased.finance/tokenomics/blp</a>.</div>
          <div className="BLP-block-section ">
            <NavLink to="/buy_blp" className="BLP-block " >
              <div className="BLP-block-icon">
                <img src={blpIcon} alt="blpIcon" style={{width: '40px', height: '40px'}} />
              </div>
              <div className="BLP-block-label">Mint</div>
            </NavLink>
            <NavLink to="/buy_blp#redeem" className="BLP-block marginbottombybase">
              <div className="BLP-block-icon">
                <img src={blpIcon} alt="blpIcon" height="40px" />
              </div>
              <div className="BLP-block-label">Redeem</div>
            </NavLink>
          </div>
        </div>
      </div>          
      <Footer />
    </div>
  );
}
