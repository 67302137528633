import React, { useState } from "react";
import useSWR from "swr";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";

const ClaimEsBase = () => {
  return (
    <div>ClaimEsBase</div>
  )
}

export default ClaimEsBase

