import React from "react";
import SEO from "../../components/Common/SEO";

import Footer from "../../Footer";
import { getPageTitle } from "../../Helpers";

import maticIcon from "../../img/logo-basechain.webp";

import "./Ecosystem.css";

export default function Ecosystem() {
  return (
    <SEO title={getPageTitle("Ecosystem Projects")}>
      <div className="DashboardV2 Page page-layout mb-5">
        
        
          

          <div className="Page-title-section mt-4">
            <div className="Page-title">SwapBased Projects</div>
            {}
          </div>
          <div className="DashboardV3-projects">
          {}
            <div className="App-card">
              <div className="App-card-title">
                SwapBased Finance
                <div className="App-card-title-icon">
                  <img src={maticIcon} style={{width: '24px', height: '24px'}} alt="maticIcon"   />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">Link</div>
                  <div>
                    <a href="https://swapbased.finance" target="_blank" rel="noopener noreferrer" className="acolor">
                      swapbased.finance
                    </a>
                  </div>
                </div>
              <div className="App-card-row">
                  <div className="label">Description</div>
                  <div>Decentralized Exchange</div>
                </div>
                <div className="App-card-row">
                  <div className="label">Publication</div>
                  <div>
                    <a
                      href="https://twitter.com/swap_based"
                      target="_blank"
                      rel="noopener noreferrer" className="acolor"
                    >
                      twitter.com/swap_based
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Page-title-section mt-4">
            <div className="Page-title">Official Sites</div>
            {}
          </div>
          <div className="DashboardV3-projects">
            <div className="App-card">
              <div className="App-card-title">
              Blp statistics
                <div className="App-card-title-icon">
                  <img src={maticIcon} style={{width: '24px', height: '24px'}} alt="maticIcon"  />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">Link</div>
                  <div>
                    <a href="stats.swapbased.finance" target="_blank" rel="noopener noreferrer" className="acolor">
                      stats.swapbased.finance
                    </a>
                  </div>
                </div>
                 <div className="App-card-row">
                  <div className="label">Description</div>
                  <div>Blp statistics & analytics dashboard</div>
                </div>
              </div>
            </div>

            {/* <div className="App-card">
              <div className="App-card-title">
              Blp leaderboard
                <div className="App-card-title-icon">
                  <img src={maticIcon} style={{width: '24px', height: '24px'}} alt="maticIcon"  />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">Link</div>
                  <div>
                    <a href="https://leaderboard.swapbased.finance/" target="_blank" rel="noopener noreferrer" className="acolor">
                    leaderboard.swapbased.finance
                    </a>
                  </div>
                </div>
              <div className="App-card-row">
                  <div className="label">Description</div>
                  <div>Trader performace leaderboard</div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="Page-title-section mt-4">
            <div className="Page-title">Partnerships and Integrations</div>
            {}
          </div>
          <div className="DashboardV3-projects">
          {}
            <div className="App-card">
              <div className="App-card-title">
                Defi Llama
                <div className="App-card-title-icon">
                  <img src={maticIcon} style={{width: '24px', height: '24px'}} alt="maticIcon"   />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">Link</div>
                  <div>
                    <a href="https://defillama.com/protocol/swapbased" target="_blank" rel="noopener noreferrer" className="acolor">
                      defillama.com
                    </a>
                  </div>
                </div>
              <div className="App-card-row">
                  <div className="label">Description</div>
                  <div>DeFi Dashboard</div>
                </div>
                {/* <div className="App-card-row">
                  <div className="label">Publication</div>
                  <div>
                    <a
                      href="https://twitter.com/DefiLlama/status/1532506582801580040"
                      target="_blank"
                      rel="noopener noreferrer" className="acolor"
                    >
                      twitter.com/DefiLlama
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="App-card">
              <div className="App-card-title">
                DappRadar
                <div className="App-card-title-icon">
                  <img src={maticIcon} style={{width: '24px', height: '24px'}} alt="maticIcon"   />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">Link</div>
                  <div>
                    <a href="https://dappradar.com/base/high-risk/cranium-exchange" target="_blank" rel="noopener noreferrer" className="acolor">
                      dappradar.com
                    </a>
                  </div>
                </div>
           <div className="App-card-row">
                  <div className="label">Description</div>
                  <div>Dapp Dashboard</div>
                </div>
                {}
              </div>
            </div> */}
          </div>
    
        <Footer />
      </div>
    </SEO>
  );
}
